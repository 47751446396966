<template>
    <div id="intent-setting">
        <content-header :headerTitle="activePageInfo.headerName" :isAutoBack="false" @goBack="goTo">
            <div slot="content-header-left-tips" class="content-header-left-tips">
                <!-- 1 未达标   2 未达标已下线 -->
                <div class="unqualified" v-if="!upToStandard">
                    {{CompanyIsOld == 'true' ? $t('intentQa.notMeetingStandards') : $t('intentQa.notMeetingStandardsOffline')}}
                    <el-tooltip
                        class="item"
                        popper-class="unqualified-tooltip"
                        effect="dark"
                        :content="$t('intentQa.unqualifiedTooltip')"
                        placement="bottom">
                        <span class="how-qualified">{{$t('intentQa.standardsTip')}}</span>
                    </el-tooltip>
                </div>
                <div :class="['guan-header',!upToStandard ? 'ml' : '']" v-if="activePageInfo.refAttrType === 'OFFICIAL'">{{$t('intentQa.officialTemplate')}}</div>
                <div :class="['guan-header',!upToStandard ? 'ml' : '']" v-else-if="activePageInfo.refAttrType === 'SELFBUILT'">{{$t('intentQa.selfTemplate')}}</div>
            </div>
        </content-header>
        <div class="intent-setting-content">
            <div class="left-menu-switch">
                <div
                    :class="[
                        'menu-item',
                        activeTabName === 'question' ? 'active' : '',
                    ]"
                    @click="handleClick('question')"
                    v-if="RP_Visible('OPEN_INTENT')">
                    <img style="margin-right: 14px;margin-top: -2px" v-show="activeTabName !== 'question'" width="15px" src="./../../assets/images/bot_intent_shibie.svg" alt="" srcset="">
                    <img style="margin-right: 14px;margin-top: -2px" v-show="activeTabName === 'question'" width="15px" src="./../../assets/images/bot_intent_shibie_ac.svg" alt="" srcset="">
                  {{$t('intentQa.identify')}}
                </div>
                <div
                    :class="[
                        'menu-item',
                        activeTabName === 'second' ? 'active' : '',
                    ]"
                    @click="handleClick('second')"
                    v-if="RP_Visible('OPEN_PROCESS')"
                >
                    <i class="iconfont guoran-tongyichicun-zenmohuifu"></i>
                  {{$t('intentQa.reply')}} <i v-if="!process" class="lack btn-icon" >{{$t('intentQa.lackOfProcess')}}</i >
                </div>
            </div>
            <div
                :class="[
                    'intent-setting-content-right',
                    activeType == 3 && emptySuperIntent ? 'super' : '',
                ]"
                v-if="activeTabName === 'question'"
            >
                <div
                    :class="['outer-pane', activeType != 1 ? 'other' : '']"
                    v-if="activeTabName === 'question'"
                >
                    <ul class="qwType">
                        <li
                            :class="activeType == 1 ? 'active-class' : ''"
                            id="question-btn"
                            @click="changeQType(1)"
                        >
                            <el-tooltip
                                v-if="isShowQualify "
                                class="item"
                                popper-class="unqualified-tooltip"
                                effect="dark"
                                :content="$t('intentQa.unqualified')"
                                placement="bottom">
                                <div class="warn-icon">{{$t('intentQa.notMeetingStandards')}}</div>
                            </el-tooltip>
                            <i
                                class="iconfont guoran-tongyichicun-yuyishibie"
                            ></i>
                            <span>{{$t('intentQa.semanticRecognition')}}</span>
                            <div :class="['num',corpusList.length > 9 ? 'max' : 'min']">{{total}}</div>
                        </li>
                        <li
                            v-if="liningShow"
                            :class="activeType == 2 ? 'active-class' : ''"
                            @click="changeQType(2)"
                            id="ruleRecognition"
                        >
                            <!-- <i
                                class="iconfont guoran-tongyichicun-guizeshibie"
                            ></i> -->
                            <img v-if="activeType === 2" src="./../../assets/images/bot_intent_rg_ac.svg" width="20px" alt="" srcset="">
                            <img v-if="activeType !== 2" src="./../../assets/images/bot_intent_rg.svg" width="20px" alt="" srcset="">
                           <span> {{$t('intentQa.ruleRecognition')}}</span>
                           <div :class="['num',questionListR.length > 9 ? 'max' : 'min']">{{questionListR.length}}</div>
                        </li>
                        <li
                            :class="activeType == 3 ? 'active-class' : ''"
                            @click="changeQType(3)"
                            id="superIntent"
                        >
                            <i class="iconfont guoran-yitu"></i>
                            <span>{{$t('intentQa.superIntent')}}</span>
                            <div :class="['super-intent',currentSuperIntentList.enable ? 'open' : 'close']">{{currentSuperIntentList.enable ? $t('botIntent.opened') : $t('botIntent.closed')}}</div>
                        </li>
                        <li :class="activeType == 4 ? 'active-class' : ''" @click="changeQType(4)" id="imageRecognition">
                            <i class="iconfont guoran-tongyichicun-tupian2"></i>
                            <span>{{$t('intentQa.imageRecognition')}}</span>
                            <div :class="['num',imageList.length > 9 ? 'max' : 'min']">{{imageList.length}}</div>
                        </li>
                    </ul>
                    <div class="new-content">
                        <!-- 语义识别 -->
                        <div
                            class="corpus"
                            v-if="activeType == 1"
                        >
                            <div
                                :class="['qList']"
                                :style="
                                    isShowCorpusRecommend && corpusList.length > 0
                                        ? 'width:60%'
                                        : ''
                                "
                                v-if="corpusList.length > 0"
                               
                            >
                                <div class="q-type"  >
                                    <div
                                        class="q-search"
                                        v-if="
                                            questionListS.length != 0 ||
                                            searchQStatus
                                        "
                                    >
                                        <el-input
                                            prefix-icon="iconfont guoran-tongyichicun-18-30-sousuo"
                                            :placeholder="$t('intentQa.textPlaceholder')"
                                            v-model="qSearchInput"
                                            ref="inputRef"
                                            @change="searchQ"
                                            @keyup.enter.native="searchQ"
                                        ></el-input>
                                    </div>

                                    <div class="questionList" v-loading="loading">
                                        <div class="questionList-inner">
                                            <el-table
                                                :header-cell-style="{
                                                    backgroundColor: '#F6F8FD',
                                                    height: '50px',
                                                    color: '#000000',
                                                    fontWeight: 400,
                                                    fontSize: '14px',
                                                    padding: '0 20px',
                                                }"
                                                v-if="
                                                    corpusList.length > 0 ||
                                                    searchQStatus
                                                "
                                                :data="corpusList"
                                                stripe
                                                style="width: 100%"
                                            >
                                                <el-table-column
                                                    class-name="qa-name-table"
                                                    show-overflow-tooltip
                                                    :label="$t('intentQa.questioning')"
                                                >
                                                    <template slot-scope="scope">
                                                        <el-tag
                                                            style="
                                                                margin-right: 8px;
                                                            "
                                                            size="mini"
                                                            v-if="
                                                                scope.row
                                                                    .defaultFlag
                                                            "
                                                            >官</el-tag
                                                        >
                                                        <span>{{
                                                            scope.row.content
                                                        }}</span>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column
                                                    prop="userName"
                                                    align="center"
                                                    :label="$t('intentQa.addPeople')"
                                                    width="150px"
                                                >
                                                    <template slot-scope="scope">
                                                        <div
                                                            class="add-user-icon-box"
                                                        >
                                                            <img
                                                                :src="
                                                                    scope.row
                                                                        .profilePhoto
                                                                "
                                                                alt=""
                                                                v-if="
                                                                    scope.row
                                                                        .profilePhoto
                                                                "
                                                            />
                                                            <div
                                                                class="add-user-icon"
                                                                v-else-if="scope.row.userName && scope.row.userName !== ''"
                                                            >
                                                                {{
                                                                    scope.row
                                                                        .userName &&
                                                                    scope.row.userName.slice(
                                                                        0,
                                                                        1
                                                                    )
                                                                }}
                                                            </div>
                                                            <span> {{scope.row.userName}}</span>
                                                        </div>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column
                                                    :label="$t('intentQa.updateTime')"
                                                    width="180px"
                                                    align="center"
                                                >
                                                    <template slot-scope="scope">
                                                        <span>{{
                                                            new Date(
                                                                scope.row.updateTime
                                                            ).Format(
                                                                "yyyy-MM-dd hh:mm:ss"
                                                            )
                                                        }}</span>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column
                                                    :width="$i18n.locale == 'en' ? '125px' : '90px'"
                                                    v-if="
                                                        RP_Visible(
                                                            'OPEN_INTENT_CORPUS_DELETE'
                                                        )
                                                    "
                                                    :label="$t('intentQa.operation')"
                                                    align="center"
                                                >
                                                    <template slot-scope="scope">
                                                        <el-popconfirm
                                                            v-if="!scope.row.defaultFlag"
                                                            :title="$t('intentQa.deleteQuesMsg')"
                                                            @onConfirm="
                                                                confirmDeleteQ(
                                                                    scope.row.id,
                                                                    scope.row.content,
                                                                    scope.$index
                                                                )
                                                            "
                                                        >
                                                            <el-button
                                                                style="
                                                                    border: none;
                                                                    background-color: transparent;
                                                                "
                                                                class="el-icon-delete handle-icon"
                                                                slot="reference"
                                                            ></el-button>
                                                        </el-popconfirm>
                                                        <span v-else>-</span>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                        </div>
                                    </div>
                                    <pagination
                                        v-if="total > 20"
                                        :pageSize="pageSize"
                                        :currentPage="currentPage"
                                        :total="total"
                                        @handleSizeChange="handleSizeChange"
                                        @currentChange="handleCurrentChange"
                                    >
                                    </pagination>
                                </div>
                            </div>
                            <recommendQuestion
                                v-if="corpusList.length > 0"
                                :isShowCorpusRecommend.sync="isShowCorpusRecommend"
                                :corpusRecommendLoading="corpusRecommendLoading"
                                :relatedQuestionList="relatedQuestionList"
                                :reGetrecommendEmptyDataLoading="reGetrecommendEmptyDataLoading"
                                :corpusList="corpusList"
                                :recommendEmptyData="recommendEmptyData"
                                :corpusRecommendPage="corpusRecommendPage"
                                @ignoreAll="ignoreAll"
                                @addAll="addAll"
                                @ignoreOne="ignoreOne"
                                @addOne="addOne"
                                @loadMore="loadMore">
                            </recommendQuestion>
                           
                        </div>
                            
                        </div>
                        <!-- 问法添加 -->
                        <div
                            v-if="(corpusList.length === 0 && isSearch) || corpusList.length > 0"
                            :class="[
                                'add-q-outer',
                                'tal',
                                'rg-type',
                                activeType == 1 && questionListSEmpty
                                    ? 'bottom'
                                    : '',
                            ]"
                            v-show="
                                RP_Visible('OPEN_INTENT_CORPUS_ADD') &&
                                activeType == 1
                            "
                        >
                            <div
                                :class="[
                                    'left-input-box',
                                    !isShowCorpusRecommend && corpusList.length != 0
                                        ? 'have-qa'
                                        : 'no-qa',
                                    isShowCorpusRecommend? 'show':'hidden'
                                ]"
                            >
                                <el-input
                                    ref="addQaInput"
                                    size="medium"
                                    :placeholder="$t('intentQa.addQaInput')"
                                    v-model.trim="addInput"
                                    :maxlength="maxLengthLimit"
                                    show-word-limit
                                    @keyup.enter.native="addQuestion"
                                >
                                </el-input>
                                <!-- <div class="fx-right">
                                    <span> | </span>
                                    <i
                                        class="iconfont guoran-tongyichicun-bianliang"
                                    ></i>
                                </div> -->
                            </div>
                            <div
                                :class="[
                                    'right-btns',
                                    !isShowCorpusRecommend && corpusList.length != 0
                                        ? 'have-qa'
                                        : 'no-qa',
                                    isShowCorpusRecommend? 'show':'hidden'
                                ]"
                                v-if="activeType == 1"
                            >
                                <el-button
                                    class="add-question-btn"
                                    :loading="addQuestionLoading"
                                    type="primary"
                                    @click="addQuestion"
                                    slot="append"
                                    >{{$t('intentQa.addQuestion')}}</el-button
                                >
                                <el-dropdown
                                    trigger="click"
                                    @command="handleCommandB"
                                    size="medium"
                                    type="primary"
                                    id="batchOperation"
                                    :class="[
                                        corpusList.length === 0 && !isSearch ? 'empty' : '',
                                    ]"
                                >
                                    <span class="batch">
                                        <i
                                            class="iconfont guoran-tongyichicun-piliangcaozuo"
                                        ></i>
                                        {{$t('intentQa.batchOperations')}}</span
                                    >
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item command="download"
                                            >{{$t('intentQa.downloadTemplate')}}</el-dropdown-item
                                        >
                                        <el-dropdown-item command="upload">
                                            <el-upload
                                                class="ensure ensureButt"
                                                :action="importFileUrl"
                                                :data="upLoadData"
                                                name="file"
                                                :onError="uploadError"
                                                :onSuccess="uploadSuccess"
                                                :beforeUpload="beforeAvatarUpload"
                                                :show-file-list="false"
                                            >
                                                <span>{{$t('intentQa.batchImport')}}</span>
                                            </el-upload>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                                 <!-- v-if="!isShowCorpusRecommend &&corpusList.length != 0" -->
                                <el-button
                                    class="open-qa-drawer"
                                    plain
                                    v-if="!isShowCorpusRecommend"
                                    @click="openCorpusRecommend()"
                                    >{{$t('intentQa.openQuestionRecommend')}}</el-button
                                >
                            </div>
                        </div>
                        <!-- 规则识别 -->
                        <div
                            v-if="activeType == 2 && questionListR.length !== 0"
                            class="rg-type rule-list"
                        >
                        
                            <div class="rg-list">
                                <el-table
                                    :data="questionListR"
                                    :header-cell-style="{
                                        backgroundColor: '#F6F8FD',
                                        height: '50px',
                                        color: '#000000',
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        padding: '0 20px',
                                    }"
                                >
                                    <el-table-column :label="$t('intentQa.rule')" align="left"  class-name="rule-name-table">
                                        <template slot-scope="scope">
                                            <span
                                                class="key-cell"
                                                v-for="(item, index) in scope.row
                                                    .keywords"
                                                :key="index"
                                            >
                                                <span>{{
                                                    item.condition === "IN"
                                                        ? $t('intentQa.contain')
                                                        : $t('intentQa.excluding')
                                                }}</span>
                                                【{{ item.wordString }}】
                                            </span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="condition"
                                        class-name="rule-sort"
                                        :label="$t('intentQa.ruleSort')"
                                        align="center"
                                    >
                                        <template slot-scope="scope">
                                            <div :class="[ scope.row.sorted ? 'sort' : 'no-sort',scope.row.keywords && scope.row.keywords.length > 1 ? 'more' : 'one']">
                                                {{ scope.row.sorted ? $t('intentQa.ordered') : $t('intentQa.disorder')}}
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        v-if="
                                            RP_Visible('OPEN_INTENT_RULE_UPDATE') ||
                                            RP_Visible('OPEN_INTENT_RULE_DELETE')
                                        "
                                        fixed="right"
                                        :label="$t('intentQa.operation')"
                                        width="100"
                                        align="center"
                                    >
                                        <template slot-scope="scope">
                                            <el-tooltip
                                                class="item"
                                                effect="dark"
                                                :content="$t('intentQa.noEditTip')"
                                                placement="top"
                                            >
                                                <em
                                                    v-if="scope.row.defaultFlag"
                                                    style="
                                                        color: #f56c6c;
                                                        margin-right: 10px;
                                                    "
                                                    class="el-icon-info handle-icon"
                                                ></em>
                                            </el-tooltip>
                                            <em
                                                v-if="
                                                    RP_Visible(
                                                        'OPEN_INTENT_RULE_UPDATE'
                                                    ) && !scope.row.defaultFlag
                                                "
                                                @click="editRg(scope.row.id)"
                                                class="guoran-tongyichicun-16-15-lianjibianji iconfont handle-icon"
                                            ></em>
                                            <el-popover
                                                placement="left"
                                                width="200"
                                                trigger="manual"
                                                v-model="scope.row.deleteVisible"
                                            >
                                                <p>{{$t('intentQa.deleteRule')}}</p>
                                                <br />
                                                <div
                                                    style="
                                                        text-align: right;
                                                        margin: 0;
                                                    "
                                                >
                                                    <el-button
                                                        size="mini"
                                                        type="text"
                                                        @click="
                                                            concelDeleteReg(
                                                                scope.row.id
                                                            )
                                                        "
                                                        >{{$t('common.cancel')}}</el-button
                                                    >
                                                    <el-button
                                                        type="primary"
                                                        size="mini"
                                                        @click="
                                                            confirmDeleteReg(
                                                                scope.row.id
                                                            )
                                                        "
                                                        >{{$t('common.confirm')}}</el-button
                                                    >
                                                </div>
                                                <em
                                                    slot="reference"
                                                    v-if="
                                                        RP_Visible(
                                                            'OPEN_INTENT_RULE_DELETE'
                                                        ) && !scope.row.defaultFlag
                                                    "
                                                    @click="deleteR(scope.row.id)"
                                                    class="guoran-tongyichicun-18-13-shanchu handle-icon iconfont"
                                                ></em>
                                            </el-popover>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                            <div
                                v-if="RP_Visible('OPEN_INTENT_RULE_ADD')"
                                class="rg-handle add-rule-btn el-icon-plus"
                                @click="addReg" >
                              {{$t('intentQa.addRule')}}
                            </div>
                        </div>
                        <!-- 超级意图 -->
                        <div
                            v-if="activeType == 3 && emptySuperIntent"
                            class="rg-type super-template"
                        >
                            <div class="super-intent">
                                <div class="item">
                                    <div class="title">{{$t('intentQa.isOpenSuperIntent')}}</div>
                                    <div
                                        class="content"
                                        style="flex-direction: row"
                                    >
                                        <!-- <div
                                            @click="superIntentSwitch"
                                        :class="[
                                                'icon-switch-box',
                                                currentSuperIntentList.enable
                                                    ? ''
                                                    : 'false',
                                            ]"
                                        >
                                            <i class="el-icon-check"></i>
                                            <div></div>
                                        </div> -->
                                        <div class="evaluate-table-switch">
                                            <el-switch
                                                @click.native.prevent="superIntentSwitch('switch')"
                                                :width="42"
                                                v-model="currentSuperIntentList.enable"
                                                active-color="#366AFF"
                                                inactive-color="#E2E2E2">
                                            >
                                            </el-switch>
                                            <span
                                            class="switch-open-icon"
                                            @click="superIntentSwitch('open')"
                                            v-if="currentSuperIntentList.enable"
                                            ><i class="iconfont guoran-a-16-17"></i
                                            ></span>
                                            <span
                                            class="switch-close-icon"
                                            @click="superIntentSwitch('close')"
                                            v-if="!currentSuperIntentList.enable"
                                            ><i class="arsenal_icon arsenalcuo1"></i
                                            ></span>
                                        </div>
                                        <div class="desc">
                                          {{$t('intentQa.openSuperIntentTip1')}}
                                        </div>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="title">{{$t('intentQa.selectedType')}}</div>
                                    <div class="right">
                                        <div
                                            class=""
                                            v-if="
                                                currentSuperIntentList
                                                    .corpusTemplate.name
                                            "
                                            style="width: auto; margin-right: 30px"
                                        >
                                            {{
                                                currentSuperIntentList
                                                    .corpusTemplate.name
                                            }}
                                        </div>
                                        <el-button
                                            v-if="
                                                RP_Visible(
                                                    'OPEN_INTENT_TEMPLATE_UPDATE'
                                                )
                                            "
                                            @click="superIntentChange()"
                                            type="primary"
                                            icon="guoran-tongyichicun-16-15-lianjibianji iconfont"
                                            round
                                            >{{$t('intentQa.editSuperIntent')}}</el-button
                                        >
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="title">{{$t('intentQa.designedQuestion')}}</div>
                                    <div
                                        class="content"
                                        style="flex-direction: row"
                                    >
                                        <span
                                            class="examplesen"
                                            v-for="(item, index) in mySentence"
                                            :key="index"
                                        >
                                            <el-tooltip
                                                v-if="item.isFillIn == true"
                                                class="item"
                                                effect="dark"
                                                :content="item.value"
                                                placement="top-start"
                                            >
                                                <span
                                                    :class="
                                                        item.isFillIn == true
                                                            ? 'isFillIn'
                                                            : ''
                                                    "
                                                    >{{ item.value }}</span
                                                >
                                            </el-tooltip>
                                            <span
                                                v-if="item.isFillIn == false"
                                                :class="
                                                    item.isFillIn == true
                                                        ? 'isFillIn'
                                                        : 'text'
                                                "
                                                >{{ item.value }}</span
                                            >
                                        </span>
                                    </div>
                                </div>
                                <div class="item last">
                                    <div class="title">{{$t('intentQa.superIntentExample')}}</div>
                                    <div
                                        class="content template-exaple"
                                        v-if="currentSuperIntentList.examples"
                                        :style="{
                                            height:
                                                currentSuperIntentList.examples
                                                    .length *
                                                    60 +
                                                'px',
                                        }"
                                    >
                                        <div
                                            v-for="(
                                                item, index
                                            ) in currentSuperIntentList.examples"
                                            :key="index"
                                        >
                                            <div></div>
                                            {{ item }}
                                        </div>
                                        <!-- <span>{{currentSuperIntentList.example}}</span> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="image-recognition" v-show="activeType == 4">
                            <imageRecognition @updateImagelist="updateImagelist" ref="imageRecognition"></imageRecognition>
                        </div>

                        <div :class="['new-no-data-content',corpusList.length > 0 && activeType == 1 ? 'min-width' : 'max-width']">
                            <!-- 无数据
                            :style="RP_Visible('OPEN_INTENT_CORPUS_ADD') && isShowCorpusRecommend && activeType == 1 ? 'width:60%' : '100%'"
                             -->
                            <div
                                :class="['empty-from-new-no-data-content',activeType == 1 ? 'qa' : '']"
                                v-if="
                                    (activeType == 3 && !emptySuperIntent) ||
                                    (activeType == 2 && questionListR.length == 0) ||
                                    (activeType == 1 && questionListSEmpty)
                                "
                                
                            >
                                <!-- 超级模板 -->
                                <div
                                    v-if="activeType == 3 && !emptySuperIntent"
                                    class="empty-rg-l"
                                >
                                    <div class="image-outer">
                                        <img
                                            class="empty-image"
                                            src="../../assets/images/no-intention.png"
                                        />
                                    </div>
                                    <div
                                        v-if="RP_Visible('OPEN_INTENT_TEMPLATE_UPDATE')"
                                        class="empty-tips"
                                    >
                                        <span class="empty-title"
                                            >{{$t('intentQa.noOpenSuperIntent')}}</span
                                        >
                                        <span class="empty-des"
                                            >{{$t('intentQa.openSuperIntentTip2')}}</span
                                        >
                                        <el-button
                                            class="super"
                                            @click="superIntentConfig()"
                                            size="small"
                                            round
                                            >{{$t('intentQa.openSuperIntent')}}</el-button
                                        >
                                    </div>
                                </div>
                                <!-- 规则 -->
                                <div
                                    v-if="activeType == 2 && questionListR.length == 0"
                                    class="empty-rg-l"
                                >
                                    <div class="image-outer">
                                        <img
                                            class="empty-image"
                                            src="../../assets/images/no-rule.png"
                                        />
                                    </div>
                                    <div
                                        v-if="RP_Visible('OPEN_INTENT_RULE_ADD')"
                                        class="empty-tips"
                                    >
                                        <span class="empty-title"
                                            >{{$t('intentQa.noRule')}}</span
                                        >
                                        <span class="empty-des"
                                            >{{$t('intentQa.noRuleTip')}}</span
                                        >
                                        <el-button
                                            class="rule"
                                            @click="addReg"
                                            round
                                            size="small"
                                            >{{$t('intentQa.addRule')}}</el-button
                                        >
                                    </div>
                                </div>
                                <!-- 问法 -->
                                <div
                                    v-if="activeType == 1 && questionListSEmpty"
                                    class="empty-rg-l qa"
                                >
                                    <div class="image-outer">
                                        <img
                                            class="empty-image"
                                            src="../../assets/images/no-question-list.png"
                                        />
                                    </div>
                                    <div class="empty-tips">
                                        <span class="empty-title"
                                            >{{$t('intentQa.noQuestion')}}</span
                                        >
                                        <span
                                            v-if="activePageInfo.refAttrType == 'OFFICIAL' || activePageInfo.refAttrType == 'SELFBUILT'"
                                            class="empty-warning"
                                            >{{$t('intentQa.noQuestionTip1')}}</span
                                        >
                                        <div class="empty-des">
                                          {{$t('intentQa.noQuestionTip2')}}
                                        </div>
                                        <div>{{$t('intentQa.noQuestionTip3')}}</div>
                                    </div>
                                </div>
                                <!-- 问法添加 -->
                            <div
                                :class="[
                                    'add-q-outer',
                                    'tal',
                                    'rg-type',
                                    'no-data'
                                ]"
                                v-show="
                                    RP_Visible('OPEN_INTENT_CORPUS_ADD') &&
                                    activeType == 1
                                "
                            >
                                <div
                                    :class="[
                                        'left-input-box','no-qa',
                                    ]"
                                >
                                    <el-input
                                        ref="addQaInput"
                                        size="medium"
                                        :placeholder="$t('intentQa.addQaInput')"
                                        v-model.trim="addInput"
                                        :maxlength="maxLengthLimit"
                                        show-word-limit
                                        @keyup.enter.native="addQuestion"
                                    >
                                    </el-input>
                                </div>
                                <div
                                    :class="[
                                        'right-btns',
                                        !isShowCorpusRecommend && corpusList.length != 0
                                            ? 'have-qa'
                                            : 'no-qa',
                                        isShowCorpusRecommend? 'show':'hidden'
                                    ]"
                                    v-if="activeType == 1"
                                >
                                    <el-button
                                        class="add-question-btn"
                                        :loading="addQuestionLoading"
                                        type="primary"
                                        @click="addQuestion"
                                        slot="append"
                                        >{{$t('intentQa.addQuestion')}}</el-button
                                    >
                                    <el-dropdown
                                        trigger="click"
                                        @command="handleCommandB"
                                        size="medium"
                                        type="primary"
                                        id="batchOperation"
                                        :class="[
                                            corpusList.length === 0 && !isSearch ? 'empty-l-btnn' : '',
                                        ]"
                                    >
                                        <span class="batch">
                                            <i
                                                class="iconfont guoran-tongyichicun-piliangcaozuo"
                                            ></i>
                                            {{$t('intentQa.batchOperations')}}</span
                                        >
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item command="download"
                                                >{{$t('intentQa.downloadTemplate')}}</el-dropdown-item
                                            >
                                            <el-dropdown-item command="upload">
                                                <el-upload
                                                    class="ensure ensureButt"
                                                    :action="importFileUrl"
                                                    :data="upLoadData"
                                                    name="file"
                                                    :onError="uploadError"
                                                    :onSuccess="uploadSuccess"
                                                    :beforeUpload="beforeAvatarUpload"
                                                    :show-file-list="false"
                                                >
                                                    <span>{{$t('intentQa.batchImport')}}</span>
                                                </el-upload>
                                            </el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                    <el-button
                                        class="open-qa-drawer"
                                        plain
                                        v-if="!isShowCorpusRecommend"
                                        @click="openCorpusRecommend()">
                                      {{$t('intentQa.openQuestionRecommend')}}
                                    </el-button>
                                </div>
                            </div>
                            </div>
                            <recommendQuestion
                                v-if="activeType == 1 && corpusList.length === 0"
                                :isShowCorpusRecommend.sync="isShowCorpusRecommend"
                                :corpusRecommendLoading="corpusRecommendLoading"
                                :relatedQuestionList="relatedQuestionList"
                                :reGetrecommendEmptyDataLoading="reGetrecommendEmptyDataLoading"
                                :corpusList="corpusList"
                                :recommendEmptyData="recommendEmptyData"
                                :corpusRecommendPage="corpusRecommendPage"
                                @ignoreAll="ignoreAll"
                                @addAll="addAll"
                                @ignoreOne="ignoreOne"
                                @addOne="addOne"
                                @loadMore="loadMore">
                            </recommendQuestion>

                           


                        </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
        <el-drawer
            id="add-answer-drewer"
            :visible.sync="drawer"
            direction="rtl"
            size="700px"
        >
            <div class="add-anser-content">
                <div class="left-component">
                    <span class="left-title">{{$t('intentQa.answerTitle')}}</span>
                    <ul class="components-list">
                        <li
                            v-for="(item, index) in componentsList"
                            @click="clickAnswerComponent(item.id)"
                            :key="index"
                            class="components-cell cursor"
                        >
                            <img
                                width="48px"
                                height="32px"
                                :src="item.url"
                                alt
                            />
                            <span>{{ item.name }}</span>
                        </li>
                    </ul>
                </div>
                <div class="right-showbox">
                    <div class="top-handle">
                        <span class="top-handle-title">{{$t('intentQa.addAnswer')}}</span>
                        <div class="top-handle-btn">
                            <el-button plain size="mini" @click="closeAddAnswer"
                                >{{$t('common.close')}}</el-button
                            >
                            <el-button
                                type="primary"
                                size="mini"
                                @click="saveAddAnswer"
                                >{{$t('common.save')}}</el-button
                            >
                        </div>
                    </div>
                    <div v-if="answerList.length === 0" class="no-answer-box">
                        <div class="image-box">
                            <img
                                width="100px"
                                height="100px"
                                src="http://img.mp.itc.cn/upload/20170621/895b5044523b41b287b01032be14b30a_th.jpg"
                                alt
                            />
                        </div>
                        <div class="no-answer-content">
                            <span class="no-answer-title">{{$t('intentQa.noAnswer')}}</span>
                            <span class="no-answer-tip"
                                >{{$t('intentQa.noAnswerTip')}}~</span
                            >
                        </div>
                    </div>
                    <div v-if="answerList.length !== 0" class="has-answer-box">
                        <ul class="answer-box">
                            <li
                                v-for="(item, index) in answerList"
                                :key="index"
                                class="answer-cell"
                            >
                                <div class="answer-cell-content">
                                    <span class="answer-cell-handle"></span>
                                    <div class="answer-cell-type">
                                        <div
                                            v-if="item.type === 1"
                                            class="type-text"
                                        >
                                            <el-input
                                                type="textarea"
                                                :rows="2"
                                                :placeholder="$t('intentQa.textPlaceholder')"
                                                v-model="item.content"
                                            ></el-input>
                                        </div>
                                        <div
                                            v-if="item.type === 2"
                                            class="type-image"
                                        >
                                            <img :src="item.url" alt />
                                        </div>
                                        <div
                                            v-if="item.type === 3"
                                            class="type-video"
                                        >
                                            <img :src="item.url" alt />
                                        </div>
                                        <div
                                            v-if="item.type === 4"
                                            class="type-button-list"
                                        >
                                            <div class="button-list-des">
                                                <span class="button-list-title"
                                                    >{{$t('intentQa.clickToIntroduce')}}</span
                                                >
                                                <div>
                                                    <el-input
                                                        :placeholder="$t('intentQa.textPlaceholder')"
                                                        v-model="item.content"
                                                        size="small"
                                                    ></el-input>
                                                </div>
                                            </div>
                                            <div class="button-list-des">
                                                <span class="button-list-title"
                                                    >{{$t('intentQa.selectQuestion')}}</span
                                                >
                                                <ul class="button-list-box">
                                                    <li
                                                        class="button-list-cell"
                                                    >
                                                        <el-input
                                                            :placeholder="$t('intentQa.textPlaceholder')"
                                                            v-model="
                                                                item.content
                                                            "
                                                            size="small"
                                                        ></el-input>
                                                        <em
                                                            class="el-icon-error"
                                                        ></em>
                                                    </li>
                                                </ul>
                                            </div>
                                            <span class="button-list-add">
                                                <em
                                                    class="el-icon-circle-plus"
                                                ></em>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="answer-cell-handle">
                                        <div class="answer-cell-handle-inner">
                                            <em
                                                @click="handleAnswer(1, index)"
                                                v-if="index !== 0"
                                                class="el-icon-top"
                                            ></em>
                                            <em
                                                @click="handleAnswer(2, index)"
                                                v-if="
                                                    index !==
                                                    answerList.length - 1
                                                "
                                                class="el-icon-bottom"
                                            ></em>
                                            <em
                                                @click="handleAnswer(4, index)"
                                                class="el-icon-delete"
                                            ></em>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="index !== answerList.length - 1"
                                    class="link-point"
                                >
                                    ...
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </el-drawer>
        <div id="add-reg">
            <popup @closeEvent="cancelSubmitReg('close')" v-if="dialogVisible">
                <div slot="popup-name">
                    {{ handleType === "add" ? $t('intentQa.newAddRule') : $t('intentQa.editRule') }}
                </div>
                <div slot="popup-tip">{{$t('intentQa.ruleTip')}}</div>
                <div slot="popup-con" class="popup-con">
                    <div
                        v-if="!showExp"
                        class="show-exp"
                        @click="changeShowExp(true)"
                    >
                      {{$t('intentQa.lookExp')}}
                    </div>
                    <div class="add-reg-content">
                        <div v-if="showExp" class="reg-exp">
                            <div class="tar-title">
                                <span>{{$t('intentQa.exp')}}</span>
                                <div class="el-icon-close"  @click="changeShowExp(false)"></div>
                            </div>
                            <img
                                src="./../../assets/images/exp_new.png"
                                alt
                                style="width: 516spx"
                            />
                        </div>
                        <div class="add-reg-box">
                            <ul class="sort-ul-rule">
                                <li
                                    :class="['cell','cell' + index]"
                                    v-for="(item, index) in intentReg.keywords"
                                    :key="item.id"
                                    :data-id="JSON.stringify(item)"
                                >
                                    <em
                                        v-if="sorted == 2"
                                        class="iconfont guoran-tongyichicun-16-10-paixu"
                                        @mouseenter="resortOptions(item,index)"
                                    ></em>
                                    <span class="reg-type">
                                        <el-radio
                                            v-model="item.condition"
                                            label="IN"
                                            >{{$t('intentQa.contain')}}</el-radio
                                        >
                                        <el-radio
                                            v-model="item.condition"
                                            label="NIN"
                                            >{{$t('intentQa.excluding')}}</el-radio
                                        >
                                    </span>
                                    <span class="reg-content">
                                        <el-input
                                            @click.stop.native="changeInput($event,item,index,'focus')"
                                            @input="changeInput($event,item,index)"
                                            :key="index"
                                            class="reg-content-value"
                                            :placeholder="$t('intentQa.regPlaceholder')"
                                            v-model.trim="item.wordsStr"
                                        ></el-input>
                                        <botKeywordRecommend 
                                            :isFixed="true"
                                            :key="'addReg' + index"
                                            :showBotKeywordRecommend.sync="item.showBotKeywordRecommend"
                                            :dataObj="item"
                                            :keyword="item.wordsStr"
                                            :dataIndex="index"
                                            :left="positionLeft"
                                            :positionTop="positionTop"
                                             @closeBotKeywordRecommend="closeBotKeywordRecommend(item)"
                                            @addToInput="addToInput($event,item,index)">
                                        </botKeywordRecommend>
                                    </span>
                                    <span
                                        v-if="intentReg.keywords.length > 1"
                                        class="reg-handle-out">
                                        <em
                                            @click="handleExpList(4, index)"
                                            class="guoran-tongyichicun-16-09-shanchu2 iconfont"
                                        ></em>
                                    </span>
                                </li>
                            </ul>
                            <span class="add-em">
                                <em
                                    @click="handleExpList(3)"
                                    class="guoran-tongyichicun-16-13-xinjian iconfont cursor"
                                ></em>
                            </span>
                        </div>

                        <div class="tal">
                            <div>{{$t('intentQa.ruleIsHasSort')}}：</div>
                            <div class="redio-bgc">
                                <el-radio v-model="sorted" label="2"
                                    >{{$t('intentQa.ordered')}}</el-radio
                                >
                                <el-radio v-model="sorted" label="1"
                                    >{{$t('intentQa.disorder')}}</el-radio
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div slot="dialog-footer">
                    <el-button
                        @click="cancelSubmitReg('close')"
                        plain
                        round
                        class="cancel-btn"
                        >{{$t('common.cancel')}}</el-button
                    >
                    <el-button
                        type="primary"
                        @click="submitReg"
                        round
                        class="confirm-btn"
                        >{{$t('common.save')}}</el-button
                    >
                </div>
            </popup>
        </div>
         <!-- 超级意图弹框 -->
        <div id="superIntentConfig-intent-add">
            <popup
                @closeEvent="superIntentDialogVisible = false"
                v-if="superIntentDialogVisible"
            >
                <div slot="popup-name">{{$t('intentQa.superIntentTitle')}}</div>
                <div slot="popup-tip">{{$t('intentQa.superIntentTip')}}</div>
                <div slot="popup-con" class="popup-con">
                    <ul class="superIntentConfig">
                        <li>
                            <div
                                class="block-add-intent"
                                v-if="isActiveSuperIntent"
                            >
                                <span class="title">
                                    <i
                                        class="iconfont guoran-tongyichicun-shili"
                                    ></i>
                                    {{$t('intentQa.selectType')}}</span
                                >
                                <el-select
                                    v-model="selectData.name"
                                    @visible-change="
                                        selectVisibleChange($event)
                                    "
                                    @change="selectTrigger()"
                                    filterable
                                    :placeholder="$t('common.selectPlaceholder')"
                                >
                                    <el-option
                                        v-for="item in superIntentList"
                                        :key="item.id"
                                        :value="item.name"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                        </li>
                        <li
                            class="exmplebox"
                            v-if="
                                isActiveSuperIntent && selectData.examples != ''
                            "
                        >
                            <div class="title">{{$t('intentQa.superIntentExample')}}</div>
                            <div class="exmple">
                                <div
                                    v-for="item in selectData.examples"
                                    :key="item.id"
                                    class="text"
                                >
                                    <div></div>
                                    {{ item }}
                                </div>
                            </div>
                        </li>
                        <!-- <div class="line"></div> -->
                        <li
                            v-if="
                                isActiveSuperIntent && selectData.examples != ''
                            "
                        >
                            <div class="blockdes">
                                <div class="title">
                                    <i
                                        class="iconfont guoran-tongyichicun-shili"
                                    ></i
                                    >{{$t('intentQa.superIntentExampleTip1')}}
                                </div>
                                <div class="des">
                                    {{$t('intentQa.superIntentExampleTip2')}}
                                </div>
                            </div>
                            <div class="config">
                                <div
                                    :class="['pattern','pattern'+index]"
                                    v-for="(item, index) in selectData
                                        .templateConfig.pattern"
                                    :key="index"
                                >
                                   <span class="keyword-recommend">
                                        <el-input
                                            @click.stop.native="changeInputSuper($event,item,index,'focus')"
                                            @input="changeInputSuper($event,item,index)"
                                            v-model.trim="item.value"
                                            :placeholder="$t('intentQa.keywordRequired')"
                                            size="small"
                                            v-if="
                                                item.isFillIn == true &&
                                                item.type != 1
                                            "
                                        ></el-input>
                                        <botKeywordRecommend 
                                            :key="'superIntentConfig' + index"
                                            :showBotKeywordRecommend.sync="item.showBotKeywordRecommend"
                                            :dataObj="item"
                                            :keyword="item.value"
                                            :dataIndex="index"
                                            :isFixed="true"
                                            :left="positionLeftSuper"
                                            :positionTop="positionTopSuper"
                                            @closeBotKeywordRecommend="closeBotKeywordRecommend(item)"
                                            @addToInput="addToInputSuper($event,item,index)">
                                        </botKeywordRecommend>
                                   </span>
                                    <span
                                        class="word"
                                        v-if="
                                            item.type == 1 &&
                                            item.isFillIn == false
                                        "
                                        >{{ item.words[0] }}</span
                                    >
                                </div>
                            </div>
                            <div class="pattern config-example">
                                <div class="title"><span>{{$t('intentQa.configExample')}}</span></div>
                                <div class="bottom-example">
                                    <div
                                        :class="[
                                            'pattern',
                                            item.isFillIn ? 'border' : '',
                                        ]"
                                        v-for="(item, index) in selectData
                                            .templateConfig.example"
                                        :key="index"
                                    >
                                        <span
                                            :class="[
                                                item.type != '1'
                                                    ? 'word input'
                                                    : 'word',
                                            ]"
                                            v-for="(
                                                todo, cindex
                                            ) in selectData.templateConfig.example[
                                                index
                                            ].words.join(',')"
                                            :key="cindex"
                                            >{{ todo }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div slot="dialog-footer">
                    <el-button
                        @click="superIntentDialogVisible = false"
                        plain
                        class="cancel-btn"
                        round
                        >{{$t('common.cancel')}}</el-button
                    >
                    <el-button
                        type="primary"
                        class="confirm-btn"
                        @click="updateIntentMatchTemplate"
                        round
                        >{{$t('common.save')}}</el-button
                    >
                </div>
            </popup>
        </div>
        <popup
            @closeEvent="progressDialogVisible = false"
            v-if="progressDialogVisible"
            :haveTips="false"
            class="no-footer">
            <div slot="popup-name">{{$t('intentQa.uploadProcess')}}</div>
            <div slot="popup-con">
                <el-progress
                    :text-inside="true"
                    :close-on-click-modal="false"
                    :stroke-width="16"
                    :percentage="uploadProgress"
                    :color="customColors"
                ></el-progress>
            </div>
        </popup>
    
    </div>
</template>
<script>
import Popup from "../../components/popup";
import pagination from "../../components/Pagination.vue";
import BotNameAndTolick from "./../../components/BotNameAndToLink";
import $ from "jquery";
import { apiKeys } from "../../const/SpecialApiKey.js";
import Driver from "driver.js"; // import driver.js
import "driver.js/dist/driver.min.css"; // import driver.js css
import steps from "./guide/intentQa";
import contentHeader from "../../components/content-top-header.vue";
import Sortable from "sortablejs";
import botKeywordRecommend from './components/botKeywordRecommend.vue';
import imageRecognition from './components/imageRecognition.vue'
import recommendQuestion from './components/recommendQuestion.vue'
export default {
    data() {
        return {
            nodeTree: [
                {
                    type: "skill",
                    name: "IT服务",
                    id: 1,
                    children: [
                        {
                            type: "class",
                            name: "打印机故障",
                            id: 12,
                            children: [
                                {
                                    type: "intent",
                                    name: "在开机情况下，无法打开视频的解决办法",
                                    id: 121,
                                    children: [
                                        {
                                            type: "node",
                                            name: "如果能识别到身高体重1",
                                            id: 1211,
                                            children: [],
                                        },
                                        {
                                            type: "node",
                                            name: "如果能识别到身高体重2",
                                            id: 1212,
                                            children: [],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            type: "class",
                            name: "电脑故障",
                            id: 13,
                            children: [
                                {
                                    type: "intent",
                                    name: "在开机情况下，无法打开视频的解决办法",
                                    id: 131,
                                    children: [
                                        {
                                            type: "node",
                                            name: "如果能识别到身高体重1",
                                            id: 1311,
                                            children: [],
                                        },
                                        {
                                            type: "node",
                                            name: "如果能识别到身高体重2",
                                            id: 1312,
                                            children: [],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
            userId: null,
            mainId: null,
            liningShow: true,
            activePageInfo: {
                // 当前页跳转信息
                name: "返回意图列表",
                gotoPageName: "botIntent",
                botId: 1,
                intentId: "",
                headerName: "",
                refAttrType: '',
            },
            activeTabName: "question",
            qSearchInput: "", // 问题搜索值
            activeType: "1",
            questionList: [],
            questionListS: [],
            questionListD: [],
            corpusList: [],
            corpusRecommend: [
                {
                    name: "今天天气怎么样啊",
                    corpusRecommend: [
                        { id: "1", name: "想法1" },
                        { id: "2", name: "想法2" },
                    ],
                },
            ],
            isShowCorpusRecommend: false,
            loading: false,
            importFileUrl: location.origin + "/api/intent-question/batch",
            upLoadData: {
                intentId: this.$route.query.intentId,
            },
            // 分页
            currentPage: 1,
            pageSize: 20,
            total: 0,
            // 添加问法
            addInput: "",
            // 规则
            questionListR: [],
            // 添加编辑规则识别
            addRegVisible: false,
            dialogVisible: false,
            // 聊天记录部分
            drawer: false,
            // 上传文件进度
            uploadProgress: 0,
            progressDialogVisible: false,
            uploadRequestUUID: null,
            reqProcessTimer: null,
            // 显示上传进度弹窗
            customColors: [
                { color: "#366AFF", percentage: 0 },
                { color: "#67C23A", percentage: 100 },
            ],
            chatList: [
                {
                    content: "怎么申请发票",
                    user: 1,
                    type: 1, // 1 文本 2 图片 3 视频 4 链接
                },
                {
                    content:
                        "报销人员报销申请报销人员报销申请报销人员报销申请报销人员报销申请报销人员报销申请",
                    user: 2,
                    type: 1, // 1 文本 2 图片 3 视频 4 链接
                },
                {
                    url: "http://img.mp.itc.cn/upload/20170621/895b5044523b41b287b01032be14b30a_th.jpg",
                    user: 2,
                    type: 2, // 1 文本 2 图片 3 视频 4 链接
                },
                {
                    url: "https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1575296288781&di=1e72b13378ec16a81e6b67bc55164d64&imgtype=0&src=http%3A%2F%2Fphotocdn.sohu.com%2F20160305%2Fmp61948554_1457109176427_8.jpeg",
                    user: 2,
                    type: 3, // 1 文本 2 图片 3 视频 4 链接
                },
                {
                    title: "您也可以选择以下问题",
                    linkList: ["多久钱到账", "发票抬头税号是什么？"],
                    user: 2,
                    type: 4, // 1 文本 2 图片 3 视频 4 点击按钮
                },
            ],
            // 添加回答部分
            componentsList: [
                {
                    name: "文本",
                    url: "http://img.mp.itc.cn/upload/20170621/895b5044523b41b287b01032be14b30a_th.jpg",
                    id: 1,
                },
                {
                    name: "图片",
                    url: "http://img.mp.itc.cn/upload/20170621/895b5044523b41b287b01032be14b30a_th.jpg",
                    id: 2,
                },
                {
                    name: "视频",
                    url: "http://img.mp.itc.cn/upload/20170621/895b5044523b41b287b01032be14b30a_th.jpg",
                    id: 3,
                },
                {
                    name: "点选",
                    url: "http://img.mp.itc.cn/upload/20170621/895b5044523b41b287b01032be14b30a_th.jpg",
                    id: 4,
                },
            ],
            answerList: [
                {
                    type: 1,
                    content: "第一条答案",
                    id: 1,
                },
                {
                    type: 2,
                    content: "第一条答案",
                    id: 2,
                },
                {
                    type: 3,
                    content: "第一条答案",
                    id: 3,
                },
                {
                    type: 4,
                    content: "第一条答案",
                    id: 4,
                },
            ], // 答案list
            radio: "1",
            showExp: false,
            intentReg: {
                sortedStr: "1",
                keywords: [],
            },
            intentRegTamp: {},
            handleType: "add",
            regId: "",
            sorted: "1",
            guidTaskId: null,
            superIntentDialogVisible: false,
            superIntentList: [{ id: "", name: "" }],
            currentSuperIntentList: {
                corpusTemplate: { examples: [""], example: "" },
                name: "diyige",
                config: [{ type: "", words: [] }],
            },
            selectData: {
                id: "",
                name: "",
                examples: [],
                config: [{ words: ["", ""], isFillIn: null }],
                templateConfig: {
                    pattern: [
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                             showBotKeywordRecommend:false
                        },
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                             showBotKeywordRecommend:false
                        },
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                             showBotKeywordRecommend:false
                        },
                    ],
                    example: [
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                             showBotKeywordRecommend:false
                        },
                    ],
                },
            },
            startSelectData: {
                id: "",
                name: "",
                examples: [],
                templateConfig: {
                    pattern: [
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                            showBotKeywordRecommend:false
                        },
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                             showBotKeywordRecommend:false
                        },
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                             showBotKeywordRecommend:false
                        },
                    ],
                    example: [
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                             showBotKeywordRecommend:false
                        },
                    ],
                },
            },
            lastSelectData: {},
            mySentence: [{ value: "", isFillIn: false }],
            exampleSentence: [{ value: "", isFillIn: false }],
            isActiveSuperIntent: false,
            isSaveEnable: false,
            settingList: { id: "", apiKey: "" },
            superIntentInputWords: [],
            emptySuperIntent: false,
            searchQStatus: false,
            questionListSEmpty: false,
            serachResultEdit: false,
            relatedQuestionList: { total: "" },
            lastCorpusName: "",
            corpusRecommendLoading: false,
            recommendEmptyData: [],
            reGetrecommendEmptyDataLoading: false,
            corpusRecommendPage: 1,
            isLoadmore: false,
            recommendType: "question", // question  work
            title: "新增规则",
            isSearch:false,
            cb:null,
            isRuleFocus:false,
            isIntentFocus:false,
            positionTop:0,
            positionLeft:0,
            positionTopSuper:0,
            positionLeftSuper:0,
            isNewAdd:false,
            setIntervals:null,
            addQuestionBoo:false,
            queryQuestionRecommendedStatusTimer:null,
            maxLengthLimit: 30,
            process:true,
            heathlyType:null,
            upToStandard:true,
            CompanyIsOld:false,
            imageList:[],
            isShowQualify:false,
            addQuestionLoading:false
        };
    },
    components: {
        BotNameAndTolick,
        contentHeader,
        pagination,
        Popup,
        botKeywordRecommend,
        imageRecognition,
        recommendQuestion
    },
    watch: {
        // qSearchInput: function(val) {
        //   console.log(val)
        //   if (val.trim() === "") {
        //     this.searchQ();
        //   }
        // },
        "recommendEmptyData.length": {
            handler: function (newValue, oldValue) {
                if (newValue > oldValue) {
                    let that = this;
                    for (
                        let index = 0;
                        index < this.recommendEmptyData.length;
                        index++
                    ) {
                        if (this.recommendEmptyData[index].reGetNum > 1) {
                            this.recommendEmptyData.splice(index, 1);
                        }
                    }
                    // setTimeout(function () {
                        that.reGetRelatedQuestionList();
                    // }, 3000);
                }
            },
            deep: true,
        },
        qSearchInput: {
            handler(qSearchInput) {
                if (this.qSearchInput == "") {
                    this.addQuestionBoo = false;
                    this.getIntentQList();
                    this.searchQ();
                }
            },
            deep: true,
        },
        questionListS: {
            handler(questionListS) {
                if (questionListS.length == 0 && !this.searchQStatus) {
                    this.questionListSEmpty = true;
                    /* setTimeout(()=>{this.questionListSEmpty = true},1000)*/
                } else if (questionListS.length != 0) {
                    this.questionListSEmpty = false;
                }
            },
            deep: true,
        },
        dialogVisible(n){
            if(n){
               this.$nextTick(() => {
                    document.querySelector('#add-reg .popup-floating-layer').addEventListener('click',() => {
                        if(this.isRuleFocus){
                            this.intentReg.keywords.forEach((item,index) => {
                                this.$set(item,'showBotKeywordRecommend',false);
                                item.showBotKeywordRecommend = false;
                            })
                            this.isRuleFocus = false;
                            this.$forceUpdate();
                        }
                       
                    })
               })
            } else {
                document.querySelector('#add-reg .popup-floating-layer').removeEventListener('click',() => {})
            }
        },
        superIntentDialogVisible(n){
            if(n){
               this.$nextTick(() => {
                    document.querySelector('#superIntentConfig-intent-add .popup-floating-layer').addEventListener('click',() => {
                        if(this.isIntentFocus){
                            this.selectData.templateConfig.pattern.forEach((item,index) => {
                                this.$set(item,'showBotKeywordRecommend',false)
                                item.showBotKeywordRecommend = false;
                            })
                            this.isIntentFocus = false;
                            this.$forceUpdate();
                        }
                    })
               })
            } else {
                document.querySelector('#superIntentConfig-intent-add .popup-floating-layer').removeEventListener('click',() => {})
            }
        },
        isShowCorpusRecommend:{
            handler(n){
                console.log(n,'nnnn');
            },
            immediate:true
        }
    },
    methods: {
        // 更新图片识别列表
        updateImagelist(data){
            this.imageList = data;
        },
        // 获取意图最新信息
        getIntentDetail(type){
            this.FetchPost(this.requestUrl.botHealthCheck.selectIntentDetailInBot,{
                // apiKey: this.settingList.apiKey,
                botId: this.$route.query.bid,
                intentIds:[this.$route.query.intentId],
                pageNo: 1,
                pageSize: 30,
                intentStatus:1,
                question:1,
                rule:1,
                superIntent:1,
                process:1,
                intentType:1
            }).then(res => {
                if (res.code === "0") {
                    this.process = res.data.list[0].hasProcess;
                    this.upToStandard = res.data.list[0].upToStandard;
                    this.CompanyIsOld = sessionStorage.getItem('CompanyIsOld')
                    // if(!type || type !== 'mounted'){
                        if(sessionStorage.getItem('isOpenHeathlyDrawer') == 'true'){
                            this.$eventBus.$emit("bot-heathly-visible", {
                                flag:true,
                                type:"updateIntentDetail",
                                intentId:this.$route.query.intentId,
                                botId:this.$route.query.bid
                            });
                        }
                    // }
                    this.$forceUpdate()
                }
            })
        },
        querySearch(queryString, cb,item) {
            this.cb = cb;
            // var restaurants = this.restaurants;
            // var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
            // // 调用 callback 返回建议列表的数据
            // cb(results);
        },
        handleSelect(datas,item){
            console.log(datas,item);
        },
        inputFn(datas,item){
            this.querySearch('',this.cb,item);
        },
        closeBotKeywordRecommend(item){
            item.showBotKeywordRecommend = false;
            this.$forceUpdate()
        },
        //添加规则 关键词的近义词 , 单个添加
        addToInput(datas,item,index){   
            item.wordsStr = datas;
            // item.showBotKeywordRecommend = false;
            this.$forceUpdate()
        },
        getElementLeft(element){
            var actualLeft = element.offsetLeft;
            var current = element.offsetParent;

            while (current !== null){
            actualLeft += current.offsetLeft;
            current = current.offsetParent;
            }
            return actualLeft;
        },
        // 添加规则 显隐推荐词下拉框
        changeInput(e,item,index,type){
           
            this.isRuleFocus = true;
            this.intentReg.keywords.forEach((v,i) => {
                v.showBotKeywordRecommend = false;
            })
            if(item.wordsStr !== ''){
                item.showBotKeywordRecommend = true;
                this.$nextTick(()=> {
                     let ele = document.querySelector('#add-reg .popup-container .cell'+index + ' .reg-content-value');
                    this.positionTop = ele.getBoundingClientRect().top+45
                    this.positionLeft = ele.getBoundingClientRect().left;
                })
            } 
            this.$forceUpdate();
        },
        // 超级意图 显隐推荐词下拉框
        changeInputSuper(datas,item,index,type){
            this.isIntentFocus = true;
            this.selectData.templateConfig.pattern.forEach((v,i) => {
                v.showBotKeywordRecommend = false;
            })
            if(item.value !== ''){
                item.showBotKeywordRecommend = true;
                this.$nextTick(()=> {
                    let ele = document.querySelector('#superIntentConfig-intent-add .popup-container .pattern'+index + ' .el-input');
                    this.positionTopSuper = ele.getBoundingClientRect().top+45
                    this.positionLeftSuper = ele.getBoundingClientRect().left;
                })
            } 
            this.$forceUpdate();
        },
        //超级意图 关键词的近义词 , 单个添加
        addToInputSuper(datas,item,index){
            item.value = datas;
            this.$forceUpdate()
        },
        // 超级意图开关
        // superIntentSwitch(){

        // },
        // 规则排序
        resortOptions(item,index) {
            let box = document.querySelector(".sort-ul-rule");
            let _this = this;
             var Sortables = new Sortable(box, {
                animation: 300,
                easing: "cubic-bezier(1, 0, 0, 1)",
                handle: ".guoran-tongyichicun-16-10-paixu",
                group: 'shared',
                forceFallback: true,
                onUpdate: function (event) {
                    //修改items数据顺序
                    let newIndex = event.newIndex,
                        oldIndex = event.oldIndex,
                        $li = box.children[newIndex],
                        $oldLi = box.children[oldIndex];
                    // 先删除移动的节点
                    box.removeChild($li);
                    // 再插入移动的节点到原有节点，还原了移动的操作
                    if (newIndex > oldIndex) {
                        box.insertBefore($li, $oldLi);
                    } else {
                        box.insertBefore($li, $oldLi.nextSibling);
                    }
                     // 更新items数组
                    let items = _this.intentReg.keywords.splice(oldIndex, 1);
                    _this.intentReg.keywords.splice(newIndex, 0, items[0]);
                },
            });
           this.intentReg.keywords = _this.intentReg.keywords;
        },
        goTo() {
            sessionStorage.setItem('IntentIsRet',1);
            this.$router.push({
                name: this.activePageInfo.gotoPageName,
                query: {
                    id: this.$route.query.bid,
                    name: this.$route.query.bName,
                    fromIntentID: this.$route.query.intentId,
                    // isRet: true,
                },
            });
        },
        //获取机器人设置状态
        getBotInfo() {
            this.settingList.id = this.$route.query.bid;
            this.FetchGet(
                this.requestUrl.bot.getBotInfo + "/" + this.settingList.id
            ).then((res) => {
                if (res.code === "0") {
                    let data = res.data;
                    this.settingList = data;
                    this.getIntentMatchTemplate(false);
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        superIntentConfig() {
            this.superIntentDialogVisible = true;
            this.isActiveSuperIntent = true;
            this.getSuperIntentList();
            let intentId = this.$route.query.intentId;
            this.FetchPost(
                this.requestUrl.superIntent.updateSuperIntentSwitch,
                {
                    enable: "true",
                    intentId: intentId,
                }
            ).then((res) => {
                if (res.code === "0") {
                    this.emptySuperIntent = false;
                    this.getIntentMatchTemplate(false);
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        superIntentChange() {
            this.superIntentDialogVisible = true;
            this.isActiveSuperIntent = true;
            this.superIntentInputWords = [];
            this.selectData = this.startSelectData;
            if (this.currentSuperIntentList.corpusTemplate.name != "") {
                this.selectData = this.currentSuperIntentList.corpusTemplate;
                let superIntentConfig = JSON.parse(
                    this.currentSuperIntentList.config
                );
                for (var i = 0; i < superIntentConfig.length; i++) {
                    let superIntentWordsStr =
                        superIntentConfig[i].words.join(",");
                    if (superIntentConfig[i].isFillIn == true) {
                        //this.selectData.templateConfig.pattern[i].value = superIntentConfig[i].words.join(",");
                        this.$set(
                            this.selectData.templateConfig.pattern[i],
                            "value",
                            superIntentWordsStr
                        );
                    }
                }
            } else {
                this.selectData = this.startSelectData;
            }
            this.getSuperIntentList();
        },
        // 获取当前意图对应的超级意图类别
        getIntentMatchTemplate(isShow) {
            this.mySentence = [];
            let intentId = this.$route.query.intentId;
            this.FetchGet(
                this.requestUrl.superIntent.getIntentMatchTemplate + intentId
            ).then((res) => {
                if (res.code === "0") {
                    let data = res.data;
                    //  this.emptySuperIntent = isShow ? isShow : false
                    if (data != null) {
                        this.currentSuperIntentList = data;
                        this.emptySuperIntent = true;
                    } else {
                        this.emptySuperIntent = false;
                    }
                    if (
                        this.emptySuperIntent &&
                        this.currentSuperIntentList != null &&
                        this.currentSuperIntentList.config != null
                    ) {
                        let configStr = JSON.parse(
                            this.currentSuperIntentList.config
                        );
                        for (var i = 0; i < configStr.length; i++) {
                            //this.mySentence[i].value = configStr[i].words.join(",")
                            this.mySentence.push({
                                value: configStr[i].words.join(","),
                                isFillIn: configStr[i].isFillIn,
                            });
                        }
                    }
                } else {
                    this.$message.error(res.message);
                }
                this.loading = false;
            });
        },
        // 超级意图功能开启关闭
        superIntentSwitch(datas) {
            if (!this.RP_Visible("OPEN_INTENT_TEMPLATE_UPDATE")) return;
            if(datas === 'open'){
               this.currentSuperIntentList.enable = false;
            } else if (datas === 'close'){
                this.currentSuperIntentList.enable = true;
            } 
            this.getSuperIntentList();
            let intentId = this.$route.query.intentId;
            let enable = this.currentSuperIntentList.enable;
            this.FetchPost(
                //this.requestUrl.superIntent.updateSuperIntentSwitch+'?enable='+enable+'&id='+id+'&intentId='+intentId,this.emptyId
                this.requestUrl.superIntent.updateSuperIntentSwitch,
                {
                    enable: enable,
                    intentId: intentId,
                }
            ).then((res) => {
                if (res.code === "0") {
                    this.getIntentMatchTemplate(true);
                    if (this.currentSuperIntentList.enable == true) {
                        this.emptySuperIntent = true;
                        this.$message.success("超级意图功能已开启！");
                    } else {
                        this.emptySuperIntent = true;
                        this.$message.success("超级意图功能已关闭！");
                    }
                    this.getIntentDetail();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        selectVisibleChange(callback) {
            if (callback) {
                this.selectData = this.startSelectData;
            } else {
                this.selectTrigger();
            }
        },
        // 选中超级意图执行方法
        selectTrigger(id) {
            this.superIntentList.forEach((item,index) => {
                item.showBotKeywordRecommend = false;
                if (item.name == this.selectData.name && item.name != this.currentSuperIntentList.corpusTemplate.name) {
                    this.selectData = item;
                }
                if (item.name == this.selectData.name &&item.name == this.currentSuperIntentList.corpusTemplate.name) {
                    this.selectData = this.currentSuperIntentList.corpusTemplate;
                }
            })
            //this.superIntentList = [];
        },
        // 获取超级意图列表
        getSuperIntentList() {
            this.FetchGet(this.requestUrl.superIntent.getSuperIntentList).then(
                (res) => {
                    if (res.code === "0") {
                        this.superIntentList = res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = true;
                }
            );
        },
        updateIntentMatchTemplate() {
            let pattern = this.selectData.templateConfig.pattern;
            for (var i = 0; i < pattern.length; i++) {
                if (pattern[i].words.length != 0) {
                    let content = pattern[i].words[0].split(" ");
                    this.superIntentInputWords.push({
                        varName: pattern[i].varName,
                        type: pattern[i].type,
                        words: content,
                        isFillIn: pattern[i].isFillIn,
                    });
                } else if (pattern[i].value) {
                    let value = pattern[i].value
                        .replace(/，/gi, ",")
                        .split(",");
                    //let newvalue = value.split(',');
                    this.superIntentInputWords.push({
                        varName: pattern[i].varName,
                        type: pattern[i].type,
                        words: value,
                        isFillIn: pattern[i].isFillIn,
                    });
                } else if (this.selectData.id == "") {
                    this.$message.error("请选择超级意图类别！");
                    return;
                } else {
                    this.$message.error("填空处不能为空，请填写内容后再保存！");
                    return;
                }
            }
            let wordInput = JSON.stringify(this.superIntentInputWords);
            this.FetchPost(
                this.requestUrl.superIntent.updateIntentMatchTemplate,
                {
                    apiKey: this.settingList.apiKey,
                    intentId: this.activePageInfo.intentId,
                    templateId: this.selectData.id,
                    config: JSON.stringify(this.superIntentInputWords),
                }
            ).then((res) => {
                if (res.code === "0") {
                    this.$message.success("保存超级意图配置成功");
                    this.getIntentMatchTemplate(true);
                    this.superIntentDialogVisible = false;
                    this.currentSuperIntentList.enable = true;
                    this.emptySuperIntent = true;
                    this.getIntentDetail();
                } else {
                    this.$notify.error({
                        title: "错误",
                        message: res.message,
                    });
                }
            });
        },
        //添加官方意图说明
        highlightOfficialIntent() {
            const driver = new Driver();
            driver.highlight({
                element: "#officialIntent",
                popover: {
                    title: "官方意图",
                    description:
                        "官方意图是指官方已经提供了基础识别能力的意图，同时您需要要补充更多的问法，不断提升识别能力",
                },
            });
        },
        //tabs中点击意图流程设计跳转
        handleClick(tab) {
            this.activeTabName = tab;
            if (tab == "second") {
                // 触发‘ChatFlow’事件
                this.toChatFlow();
            }
        },
        //
        switchRecommend(type) {
            this.recommendType = type;
        },
        //跳转至意图流程设计页面
        toChatFlow() {
            this.$router.push({
                name: "chatFlow",
                query: {
                    id: this.$route.query.bid,
                    bid: this.$route.query.bid,
                    bName: this.$route.query.bName,
                    intentId: this.$route.query.intentId,
                    intentName: this.$route.query.intentName,
                    refAttrType: this.$route.query.refAttrType || '',
                    refApiKey: this.$router.currentRoute.query.refApiKey,
                },
            });
        },
        changeQType(id) {
            this.activeType = id;
            if ((id = "3")) {
                this.getIntentMatchTemplate(true);
            }
        },
        addReg() {
            this.handleType = "add";
            this.dialogVisible = true;
            this.intentReg = {
                keywords: [
                    {
                        condition: "IN",
                        words: [""],
                        wordsStr: "",
                    },
                ],
            };
        },
        changeAddRegVisible(value) {
            this.dialogVisible = value;
            this.addRegVisible = value;
        },
        formatDate(data) {
            return this.utils.formatDate(data);
        },
        // 搜索问法
        searchQ() {
            if (this.qSearchInput != "") {
                this.isSearch = true;
                this.serachResultEdit = false;
                this.searchQStatus = true;
                this.addQuestionBoo = false;
                this.getIntentQList("search");

            }
            // this.qSearchInput.trim() !== "" &&
        },
        // 返回问法列表
        goBackIntentQList() {
            this.searchQStatus = false;
            this.addQuestionBoo = false;
            this.getIntentQList();

            this.qSearchInput = "";
        },
        // 返回问法列表
        goBackIntentQListHaveWord() {
            this.serachResultEdit = true;
            this.$refs.inputRef.$el.children[0].focus();
        },
        // 添加问法
        addQuestion() {
            if (this.addInput !== "") {
                if(this.addQuestionLoading )return false
                this.addQuestionLoading = true;
                this.isSearch = false;
                this.FetchPost(this.requestUrl.intentQuestion.addIntentQ, {
                    intentId: this.activePageInfo.intentId,
                    question: this.addInput,
                }).then((res) => {
                    this.addQuestionLoading = false;
                    if (res.code === "0") {
                        this.addInput = "";
                        this.getIntentQList();
                        this.isShowCorpusRecommend = true;
                        this.corpusRecommendLoading = true;
                        let that = this;
                        // 立即调用此接口 , 会出现没有信息的情况 延迟时间较短也会获取不到
                        setTimeout(() => {
                            clearInterval(this.queryQuestionRecommendedStatusTimer);
                            this.queryQuestionRecommendedStatus(this.lastCorpusName);
                            this.lastCorpusName = this.addInput;
                       },1500)
                        this.getIntentDetail()
                    } else {
                        this.$notify.error({
                            title: "错误",
                            message: res.message,
                        });
                        // this.$message.error();
                    }
                });
            } else {
                this.$message.error("问法不能为空！");
            }
        },
        // 查询问法推荐执行状态
        queryQuestionRecommendedStatus(lastCorpusName,isMounted){
            this.queryQuestionRecommendedStatusTimer = setInterval(() => {
                this.FetchGet(this.requestUrl.relatedQuestion.queryQuestionRecommendedStatus + this.activePageInfo.intentId).then((res) => {
                    if (res.code === "0") {
                        if(res.data){
                            clearInterval(this.queryQuestionRecommendedStatusTimer);
                            if(isMounted){
                                this.onloadGetRelatedQuestionList();
                            } else {
                                this.getRelatedQuestionList(lastCorpusName);
                            }
                        } else {
                            clearInterval(this.queryQuestionRecommendedStatusTimer);
                            // if(isMounted){

                            // } else {
                                this.queryQuestionRecommendedStatus(lastCorpusName,isMounted);
                            // }
                            
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                })
            }, 1000);
        },
        //打开问法推荐
        openCorpusRecommend() {
            this.isShowCorpusRecommend = true;
            this.getRelatedQuestionList();
        },
        //关闭问法推荐
        closeCorpusRecommend() {
            this.isShowCorpusRecommend = false;
        },
        // 操作时获取关联问法推荐列表
        reGetRelatedQuestionList() {
             this.isNewAdd = false;
            this.FetchGet(
                this.requestUrl.relatedQuestion.getRelatedQuestionList,
                {
                    intentId: this.$route.query.intentId,
                }
            ).then((res) => {
                if (res.code === "0") {
                    // this.reGetrecommendEmptyDataLoading = res.data.list.length > 0 ? true : false;
                     this.relatedQuestionList = res.data;
                    for (let index = 0; index < res.data.list.length; index++) {
                        for (
                            let s = 0;
                            s < this.recommendEmptyData.length;
                            s++
                        ) {
                            if (
                                res.data.list[index].qcontent ==
                                this.recommendEmptyData[s].content
                            ) {
                                this.relatedQuestionList = res.data;
                                document.getElementById(
                                    "corpusContent"
                                ).scrollTop = 0;
                                this.recommendEmptyData.splice(s, 1);
                            }
                            this.recommendEmptyData[s].reGetNum =
                                this.recommendEmptyData[s].reGetNum + 1;
                        }
                    }
                } else {
                    this.$message.error(res.message);
                }
            });
            let that = this;
            // setTimeout(() => {
                that.reGetrecommendEmptyDataLoading = false;
            // }, 5000);
        },
        // 获取关联问法列表
        getRelatedQuestionList(qName) {
            this.FetchGet(
                this.requestUrl.relatedQuestion.getRelatedQuestionList,
                {
                    intentId: this.$route.query.intentId,
                }
            ).then((res) => {
                if (res.code === "0") {
                    // for (let index = 0; index < res.data.list.length; index++) {
                    //     if (res.data.list[index].qcontent == qName) {
                    //         this.isNewAdd = true;
                    //         res.data.list[index].isNewAdd = true;
                    //         this.corpusRecommendLoading = false;
                    //         this.isLoadmore = false;
                    //         this.relatedQuestionList = res.data;
                    //         document.getElementById(
                    //             "corpusContent"
                    //         ).scrollTop = 0;
                    //     } 
                    // }
                    res.data.list.forEach((item,index) => {
                        if (item.qcontent == qName) {
                            this.isNewAdd = true;
                            item.isNewAdd = true;
                            this.corpusRecommendLoading = false;
                            this.isLoadmore = false;
                            this.relatedQuestionList = res.data;
                            document.getElementById(
                                "corpusContent"
                            ).scrollTop = 0;
                        } 
                    })
                    if (this.corpusRecommendLoading == true) {
                        //this.reGetRelatedQuestionList(qName);
                        this.recommendEmptyData.push({
                            content: qName,
                            reGetNum: 0,
                        });
                        this.corpusRecommendLoading = false;
                    }
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 页面初始化时获取关联问法推荐列表
        onloadGetRelatedQuestionList() {
            this.isNewAdd = false;
            this.FetchGet(
                this.requestUrl.relatedQuestion.getRelatedQuestionList,
                {
                    intentId: this.$route.query.intentId,
                    // pageSize: 10,
                }
            ).then((res) => {
                if (res.code === "0") {
                    this.relatedQuestionList = res.data;
                    if(res.data.list.length > 0){
                        this.isShowCorpusRecommend = true;
                    }
                    console.log(this.relatedQuestionList,'页面初始化时获取关联问法推荐列表');
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        loadMore() {
            this.isLoadmore = true;
            this.corpusRecommendPage += 1;
             this.isNewAdd = false;
            this.FetchGet(
                this.requestUrl.relatedQuestion.getRelatedQuestionList,
                {
                    intentId: this.$route.query.intentId,
                    pageNo: this.corpusRecommendPage, //请求页数,
                    pageSize: 10,
                }
            ).then((res) => {
                if (res.code === "0") {
                    this.relatedQuestionList.list =
                        this.relatedQuestionList.list.concat(res.data.list); //将请求回来的数据和上一次进行组合
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        ignoreAll(qId) {
            this.FetchPost(this.requestUrl.relatedQuestion.ignoreAllRelatedQuestion+"?qId="+qId).then((res) => {
                if (res.code === "0") {
                    this.onloadGetRelatedQuestionList();
                    this.addQuestionBoo = false;
                    this.getIntentQList();
                } else {
                    this.$notify.error({
                        title: "错误",
                        message: res.message,
                    });
                    // this.$message.error();
                }
            });
        },
        ignoreOne(rqId) {
            this.FetchPost(
                this.requestUrl.relatedQuestion.ignoreOneRelatedQuestion+"?rqId="+rqId).then((res) => {
                if (res.code === "0") {
                    this.onloadGetRelatedQuestionList();
                } else {
                    this.$notify.error({
                        title: "错误",
                        message: res.message,
                    });
                    // this.$message.error();
                }
            });
        },
        addAll(qId) {
            this.FetchPost(this.requestUrl.relatedQuestion.addAllRelatedQuestion+"?qId="+qId).then((res) => {
                if (res.code === "0") {
                    this.getIntentDetail();
                    this.onloadGetRelatedQuestionList();
                    this.addQuestionBoo = false;
                    this.getIntentQList();
                } else {
                    this.$notify.error({
                        title: "错误",
                        message: res.message,
                    });
                    // this.$message.error();
                }
            });
        },
        addOne(rqId) {
            this.FetchPost(
                this.requestUrl.relatedQuestion.addOneRelatedQuestion+'?rqId='+rqId).then((res) => {
                if (res.code === "0") {
                    this.getIntentDetail();
                    this.onloadGetRelatedQuestionList();
                    this.addQuestionBoo = false;
                    this.getIntentQList();
                } else {
                    this.$notify.error({
                        title: "错误",
                        message: res.message,
                    });
                    // this.$message.error();
                }
            });
        },
        // 删除问法
        deleteQ(id) {
            /* this.questionListS.forEach(item => {
        item.id === id
          ? (item.deleteVisible = true)
          : (item.deleteVisible = false);
      });
      this.questionListD.forEach(item => {
        item.id === id
          ? (item.deleteVisible = true)
          : (item.deleteVisible = false);
      }); */
            this.deleteVisible = true;
        },
        // 取消删除规则
        concelDeleteReg(id) {
            this.questionListR.forEach((item) => {
                if (item.id === id) {
                    item.deleteVisible = false;
                }
            });
        },
        // 确认删除问法
        confirmDeleteQ(id, content,indexs) {
            let list = JSON.parse(JSON.stringify(this.corpusList));
            list.splice(indexs,1)
            if (this.recommendEmptyData.length > 0) {
                for (
                    let index = 0;
                    index < this.recommendEmptyData.length;
                    index++
                ) {
                    if (this.recommendEmptyData[index].content == content)
                        this.recommendEmptyData.splice(index, 1);
                }
            }
            this.FetchDelete(
                this.requestUrl.intentQuestion.deleteIntentQ,
                id
            ).then((res) => {
                if (res.code === "0") {
                    this.questionListS.forEach((item) => {
                        item.deleteVisible = false;
                    });
                    this.questionListD.forEach((item) => {
                        item.deleteVisible = false;
                    });
                    if(list && list.length > 0){

                    } else {
                        this.currentPage = this.currentPage - 1 > 0 ?  this.currentPage - 1 : 1;
                    }
                    this.onloadGetRelatedQuestionList();
                    this.getIntentDetail();
                } else {
                    this.$message.error(res.message);
                }
                this.addQuestionBoo = false;
                this.getIntentQList();
            });
        },
        // 批量操作
        handleCommandB(command) {
            if (command === "download") {
                window.location.href =
                    location.origin +
                    this.requestUrl.intentQuestion.downloadIntentQTemp;
            }
        },
        // 删除规则
        deleteR(id) {
            this.questionListR.forEach((item) => {
                item.id === id
                    ? (item.deleteVisible = true)
                    : (item.deleteVisible = false);
            });
            this.questionListR = [...this.questionListR];
        },
        // 获取问法识别列表
        getIntentQList(type) {
            // this.corpusList = [];
            this.loading = true;
            let params = {
                intentId: this.activePageInfo.intentId,
                page: type && type === 'search' ? 1 : this.currentPage,
                size: 20,
            };
            params.keyWord = this.qSearchInput.trim();

            this.FetchGet(
                this.requestUrl.intentQuestion.getIntentQList,
                params
            ).then((res) => {
                this.questionListD = [];
                this.questionListS = [];
                if (res.code === "0") {
                    this.corpusList = res.data.list || [];
                    if (res.data.list.length > 0) {
                        // this.corpusList = res.data.list;
                        res.data.list.forEach((item, index) => {
                            item.deleteVisible = false;
                            if (index % 2 !== 0) {
                                this.questionListD.push(item);
                            } else {
                                this.questionListS.push(item);
                            }
                        });




                        if(this.addQuestionBoo){
                            this.setIntervals = setInterval(() => {
                                this.FetchGet(
                                    this.requestUrl.intentQuestion.searchIntentStatus+'/'+res.data.list[0].id,
                                    params
                                ).then((res) => {
                                    if(res.data.rqCompleted){
                                        clearInterval(this.setIntervals);
                                        this.addQuestionBoo = false;
                                        this.getRelatedQuestionList(this.lastCorpusName);
                                    } else {
                                    
                                    }
                                })        
                            }, 2000);
                        } else {
                            clearInterval(this.setIntervals);
                        }
                    } 
                    // else {
                    //     this.isShowCorpusRecommend = false;
                    // }
                    this.total = res.data.total;
                    this.isShowQualify = (this.activePageInfo.refAttrType !== 'OFFICIAL' && this.activePageInfo.refAttrType !== 'SELFBUILT') && this.total < 20
                    this.loading = false;
                    if(type !== 'search'){
                        this.$nextTick(() => {
                            this.$refs.addQaInput.focus();
                        })
                    }
                  
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 批量上传
        // 上传成功后的回调
        uploadSuccess(response, file, fileList) {
            if (response.code === "0") {
                this.progressDialogVisible = true;
                this.uploadRequestUUID = response.data;
                this.uploadProgress = 0;
                // this.reqProcessTimer = setInterval(() => {
                    this.requestUploadProcess();
                    this.getIntentDetail();
                // }, 2000);
            } else {
                this.$message.error(res.message);
            }
        },
        requestUploadProcess() {
            if (
                this.progressDialogVisible == null ||
                this.progressDialogVisible === false
            ) {
                // clearInterval(this.reqProcessTimer);
                return;
            }
            this.FetchGet(
                this.requestUrl.intentQuestion.batchuploadIntentQProcess,
                {
                    uid: this.uploadRequestUUID,
                }
            ).then((res) => {
                if (res.code === "0") {
                    this.uploadProgress = res.data;
                    if (res.data == 100) {
                       setTimeout(() => {
                            this.progressDialogVisible = false;
                            // clearInterval(this.reqProcessTimer);
                            this.currentPage = 1;
                            this.addQuestionBoo = false;
                            this.getIntentQList();
                       }, 1000);
                    }
                } else {
                    this.$message.error(res.message);
                    this.progressDialogVisible = false;
                }
            });
        },
        // 上传错误
        uploadError(response, file, fileList) {
            if (response.code !== "0") {
                this.$message.error("上传失败，请重试!");
            }
        },
        // 上传前对文件的大小的判断
        beforeAvatarUpload(file) {
            const extension = file.name.split(".")[1] === "txt";
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!extension) {
                this.$message.error("上传模板只能是 txt 格式!");
            }
            if (!isLt2M) {
                this.$message.error("上传模板大小不能超过 2MB!");
            }
            return extension && isLt2M;
        },
        // 获取规则列表
        getRgList(param) {
            this.FetchGet(this.requestUrl.intentKeywordRule.getIntentKRList, {
                intentId: this.activePageInfo.intentId,
            }).then((res) => {
                if (res.code === "0") {
                    this.questionListR = res.data;
                    this.questionListR.forEach((item) => {
                        item.keywords.forEach((cell) => {
                            cell.wordString = cell.words.join(",");
                        });
                    });
                    this.dialogVisible = false;
                    this.loading = false;
                } else {
                    this.dialogVisible = false;
                    this.$message.error(res.message);
                }
            });
        },
        // 确认删除规则
        confirmDeleteReg(id) {
            this.FetchDelete(
                this.requestUrl.intentKeywordRule.deleteIntentKR,
                id
            ).then((res) => {
                if (res.code === "0") {
                    this.questionListR.forEach((item) => {
                        item.deleteVisible = false;
                    });
                    this.getIntentDetail();
                } else {
                    this.$message.error(res.message);
                }
                this.getRgList();
            });
        },

        // 确认或者取消删除规则
        closePopover() {
            // this.$refs[`popover-` + id].doClose();
        },
        // 分页操作
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.addQuestionBoo = false;
            this.getIntentQList();
        },
        // 点击答案组件
        clickAnswerComponent(id) {
            switch (id) {
                case 1: // 文本
                    this.answerList.push({
                        content: "string",
                        paredContent: {},
                        type: "IMG",
                    });
                    break;
                case 2: // 图片
                    this.answerList.push({
                        content: "string",
                        paredContent: {},
                        type: "IMG",
                    });
                    break;
                case 3: // 视频
                    this.answerList.push({
                        content: "string",
                        paredContent: {},
                        type: "IMG",
                    });
                    break;
                case 4: // 点选
                    this.answerList.push({
                        content: "string",
                        paredContent: {},
                        type: "IMG",
                    });
                    break;
                default:
                    break;
            }
        },
        // 关闭添加答案抽屉
        closeAddAnswer() {
            this.drawer = false;
        },
        // 保存添加答案
        saveAddAnswer() {
            this.drawer = false;
        },
        // 答案cell操作
        handleAnswer(index, value) {
            console.log(index, value);
        },
        // 规则操作 编辑规则
        editRg(id) {
            this.handleType = "edit";
            this.regId = id;
            this.questionListR.forEach((item) => {
                if (item.id === id) {
                    this.sorted = item.sorted ? "2" : "1";
                    this.intentReg = JSON.parse(JSON.stringify(item));
                }
            });
            this.intentReg.keywords.forEach((item) => {
                item.wordsStr = item.words.join(",");
                // 添加规则弹框中关键词推荐的近义词 , 下拉框显隐
                item.ShowBotKeywordRecommend = false;
            });
            this.dialogVisible = true;
        },

        // 是否展示示例
        changeShowExp(value) {
            this.showExp = value;
        },
        // 操作规则列表
        handleExpList(type, index) {
            // 添加
            if (type == 3) {
                this.intentReg.keywords.push({
                    condition: "IN",
                    wordsStr: "",
                    ShowBotKeywordRecommend:false, // 添加规则弹框中关键词推荐的近义词 , 下拉框显隐
                });
            }
            // 删除
            if (type == 4) {
                this.intentReg.keywords.splice(index, 1);
            }
            this.intentReg.keywords = [...this.intentReg.keywords];
        },
        // 保存设置
        submitReg() {
            let arr = [];
            this.intentReg.keywords.forEach((item) => {
                item.words = item.wordsStr;
                if (item.wordsStr !== "") {
                    arr.push(item);
                }
            });
            if (arr.length !== 0) {
                this.handleType === "add" &&
                    this.FetchPost(
                        this.requestUrl.intentKeywordRule.addIntentKR,
                        {
                            intentId: this.$route.query.intentId,
                            keywords: arr,
                            sorted: this.sorted == "2" ? true : false,
                        },
                        { emulateJSON: true }
                    ).then((res) => {
                        if (res.code === "0") {
                            this.dialogVisible = false;
                            this.cancelSubmitReg("close");
                            this.$message.success("规则添加成功！");
                            this.getIntentDetail();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
            } else {
                this.dialogVisible = false;
            }

            this.handleType === "edit" &&
                this.FetchPut(
                    this.requestUrl.intentKeywordRule.updateIntentKR,
                    this.regId,
                    {
                        intentId: this.$route.query.intentId,
                        keywords: arr,
                        sorted: this.sorted == "2" ? true : false,
                    },
                    { emulateJSON: true }
                ).then((res) => {
                    if (res.code === "0") {
                        this.dialogVisible = false;
                         this.cancelSubmitReg("close");
                        this.$message.success("规则更新成功！");
                    } else {
                        this.$message.error(res.message);
                    }
                });
        },
        //初次登陆引导层
        guide() {
            this.driver.defineSteps(steps);
            this.driver.start();
            var driver = document.getElementById("driver-page-overlay");
            var driver2 = document.getElementById(
                "driver-highlighted-element-stage"
            );
            //driver.style.cssText = "z-index:10000!important;"
            //driver2.style.cssText = "z-index:1000;"
        },
        // 取消保存
        cancelSubmitReg(value) {
            if (value === "close") {
                this.getRgList(0);
            }
        },
    },

    destroyed() {
        clearTimeout(this.guidTaskId);
    },
    mounted() {
        this.getIntentDetail('mounted');
        clearInterval(this.queryQuestionRecommendedStatusTimer);
        this.$eventBus.$on("test-window-visible", (visible) => {
            this.isShowCorpusRecommend = !visible;
        });
        this.getBotInfo();
        this.userId = localStorage.getItem("_uid");
        this.mainId = localStorage.getItem("_mainId");
        if (this.mainId === "43ca904b7c6c40e98cc053e42d2931ab") {
            this.liningShow = false;
            if (JSON.parse(localStorage.getItem("userInfo")).username === "lining@guoranbot.com") {
                this.liningShow = true;
            }
        }
        
        this.activePageInfo.headerName = this.$route.query.intentName;
        this.activePageInfo.intentId = this.$route.query.intentId;
        this.activePageInfo.refAttrType = this.$route.query.refAttrType || '';
        // if (
        //     this.$route.query.refAttrType == false ||
        //     this.$route.query.isDefault == "false" ||
        //     this.$route.query.isDefault == undefined
        // ) {
        //     this.activePageInfo.isDefault = false;
        // } else {
        //     this.activePageInfo.isDefault = true;
        // }
        this.addQuestionBoo = false;
        // 获取问法识别列表
        this.getIntentQList();
        // this.$nextTick(() => {
        //     this.queryQuestionRecommendedStatus('',true)
        // })
        this.onloadGetRelatedQuestionList();
        this.getRgList();
        this.$refs.imageRecognition.getImgageList();
        if (
            !this.RP_Visible("OPEN_INTENT") &&
            this.RP_Visible("OPEN_PROCESS")
        ) {
            this.toChatFlow();
        }
        //引导层
        this.driver = new Driver({
            className: "scoped-class", // className to wrap driver.js popover
            animate: true, // Animate while changing highlighted element
            opacity: 0.75, // Background opacity (0 means only popovers and without overlay)
            padding: 10, // Distance of element from around the edges
            allowClose: true, // Whether clicking on overlay should close or not
            overlayClickNext: true, // Should it move to next step on overlay click
            doneBtnText: "完成", // Text on the final button
            closeBtnText: "关闭", // Text on the close button for this step
            nextBtnText: "下一步", // Next button text for this step
            prevBtnText: "上一步", // Previous button text for this step
            // Called when moving to next step on any step
        });
        //页面加载完成后出现引导层
       ;
        let refApiKey = this.$router.currentRoute.query.refApiKey;
        let that = this;
        this.$nextTick(() => {
            // 确保dom异步加载完毕
            if (
                refApiKey ===
                apiKeys[process.env.VUE_APP_CONFIG_ENV].SAMPLE_BOT_API_KEY
            ) {
                that.guidTaskId = setTimeout(() => {
                    this.guide();
                }, 4000);
            }
        });
        // 用户英文意图POC 白名单处理
        if( (localStorage.getItem('_mainId') === '50a9fb43e7084fdfa450e528c8770d4e' && this.$route.query.bid === '1951') ||  localStorage.getItem('_mainId') == '6fc7f7344ba046f68de662c021214eb3' || localStorage.getItem('_mainId') == 'ab0f90737c8b4f2d85ba2157e4473110'){
            this.maxLengthLimit = 200;
        }
        if (localStorage.getItem('_mainId') === '56bec4b273ca4534b8c6d05479908e81' || localStorage.getItem('_mainId') === 'e2f80aecaf7c11eabdcd506b4b2f3acc' || localStorage.getItem('_mainId') === '1419b09cb2ce11ea8f0900163e0964d5' || localStorage.getItem('_mainId') === 'b52d52cd3533409fb4b75af79868575f') {
            this.maxLengthLimit = 50;
        }
    },

  
};
</script>
<style lang="less" scoped>
@import "./../../assets/less/intentQa/intentQa.less";
@import "./../../assets/less/main/common.less";
@outer-border: solid 2px @system_bordercolor_3;
  
</style>