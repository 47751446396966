<template>
    <div class="botNameAndToLink">
        <ul>
            <li class="leftInfo cursor">
                <span @click="goTo" class="gotoPage">
                    <em class="el-icon-d-arrow-left"></em>
                    {{activePageInfo.name}}
                </span>
                <span class="botName">{{activePageInfo.botName}}</span>
            </li>
            <li class="botTest">
                <el-button
                    type="primary"
                    size="small"
                    icon="el-icon-service"
                    @click="drawer = true"
                >测试一下</el-button>
            </li>
        </ul>
        <div>
            <el-drawer
                id="test-box"
                :modal-append-to-body="modalAppendToBody"
                :wrapperClosable="wrapperClosable"
                :modal="modal"
                title
                size="380px"
                :visible.sync="drawer"
                :direction="direction"
                :before-close="handleClose"
            >
                <div>对话测试</div>
            </el-drawer>
        </div>
    </div>
</template>
<script>
export default {
    props: ["activePageInfo"],
    data() {
        return {
            drawer: false,
            direction: "rtl",
            modal: false,
            wrapperClosable: false,
            modalAppendToBody: true
        };
    },
    methods: {
        handleClose(done) {
            done();
            // this.$confirm("确认关闭？")
            //     .then(_ => {
            //         done();
            //     })
            //     .catch(_ => {});
        },
        goTo() {
            this.$router.push({
                name: this.activePageInfo.gotoPageName
            });
        },
        // 测试当前机器人
        botTest() {}
    },
    mounted() {}
};
</script>
<style lang="less">
@import "./../assets/less/main/common.less";
.botNameAndToLink {
    background-color: @system_bordercolor_4;
    padding: 16px;
    ul {
        display: flex;
        justify-content: space-between;
        .leftInfo {
            flex: auto;
            height: 38px;
            line-height: 38px;
            display: flex;
            justify-content: flex-start;
            .botName {
                font-size: 16px;
                margin-left: 16px;
            }
            .gotoPage {
                flex: none;
                width: 80px;
                font-size: 12px;
                color: @system_fontcolor_sub;
            }
        }
        .botTest {
            width: 200px;
            text-align: right;
        }
    }
}
#test-box{
    width: 420px;
    position: absolute;
    left: calc(~"100vw - 420px") !important;
}
</style>


