<template>
   <div class="right-qa-recomment" v-if="RP_Visible('OPEN_INTENT_CORPUS_ADD') && isShowCorpusRecommend">
        <div class="recommend-header-top">
            <!-- <div :class="['recommend-item',activeTabName === 'work' ? 'active' : '']" @click="switchRecommend('work')">
工单问题推荐
</div> -->
            <div
                :class="[
                    'recommend-item',
                    'active',
                ]"
            >
                <div>
                    <i class="el-icon-loading" v-if="corpusRecommendLoading"></i>
                    {{$t('intentQa.recommendation')}}
                    <el-tooltip class="item" effect="dark" placement="top-start">
                    <div slot="content">{{$t('intentQa.recommendationTip1')}}
                        <br/>
                      {{$t('intentQa.recommendationTip2')}}
                        <br/>
                        {{$t('intentQa.recommendationTip3')}}
                    </div>
                        <i class="iconfont guoran-tongyichicun-18-16-youxianghouzhuishuomingtishifuhe"></i>
                    </el-tooltip>
                </div>
                <div
                    class="right-close iconfont guoran-shanchu"
                    @click="closeCorpusRecommend"
                ></div>
            </div>
        </div>
        <div
            id="corpusRecommend"
            :class="['corpusRecommend',relatedQuestionList.total == 0 &&!reGetrecommendEmptyDataLoading &&!corpusRecommendLoading ? 'no-data-list' : '',corpusList.length === 0 ? 'max-heigth' : '']"
            v-loading="corpusRecommendLoading"
            :element-loading-text="$t('intentQa.loading')"
        >
            <div 
                
                :class="['corpus-content']" 
                id="corpusContent">
                <div
                    class="EmptyData"
                    v-if="
                        relatedQuestionList.total == 0 &&
                        !reGetrecommendEmptyDataLoading &&
                        !corpusRecommendLoading
                    "
                >
                    <img
                        src="../../../assets/images/no-question.png"
                        width="60%"
                    />
                    <p class="title">{{$t('intentQa.noRecommend')}}</p>
                    <p class="desc">
                      {{$t('intentQa.noRecommendTip')}}
                    </p>
                </div>

                <template
                    v-if="reGetrecommendEmptyDataLoading"
                >
                    <div
                        
                        :class="['corpusRecommend-item']"
                        v-for="item in recommendEmptyData"
                        :key="item.id"
                    >
                        <div class="item-question">
                            <div class="had-qa">
                              {{$t('intentQa.existingQuestion')}}
                            </div>
                            <!-- <div class="title">
                                {{ item.content }}
                            </div> -->
                                <div class="title">
                                <span v-if="item.content.length < 10"> {{ item.content}}</span>
                                <el-tooltip class="item" effect="dark" :content="item.content" placement="top-start" v-else>
                                    <span>{{item.content.slice(0,10)}}... </span>
                                </el-tooltip>
                            </div>
                        </div>
                        <!-- <div class="loading">
                            <img
                                src="../../assets/images/5-130H2191536.gif"
                            />
                            <p>正在生成推荐的相关问法</p>
                        </div> -->
                    </div>
                </template>
                <!-- <div
                    class="corpusRecommend-item"
                    v-if="corpusRecommendLoading"
                >
                    <div class="item-question">
                        <div class="had-qa">已有问法：</div>
                        <div class="title">
                            {{ lastCorpusName }}
                        </div>
                    </div>
                    <div class="loading">
                        <img
                            src="../../assets/images/5-130H2191536.gif"
                        />
                        <p>正在生成推荐的相关问法</p>
                    </div>
                </div> -->
                <!-- item.isNewAdd ? 'isNewAdd':'' -->
                <!-- item.isNewAdd?item.relatedQuestions.length*40+40+'px':'auto' -->
                <div
                    :class="['corpusRecommend-item',item.isNewAdd ? 'isNewAdd':'']"
                    v-for="(
                        item, index
                    ) in relatedQuestionList.list"
                    :key="index"
                >
                    <div class="item-question">
                        <div>
                            <div class="had-qa">
                                {{$t('intentQa.existingQuestion')}}
                            </div>
                            <!-- <div class="title">
                                {{ item.qcontent }}
                            </div> -->
                            <div class="title one-column-ellipsis">
                                <span v-if="item.qcontent.length < 10"> {{ item.qcontent}}</span>
                                <el-tooltip class="item" effect="dark" :content="item.qcontent" placement="top-start" v-else>
                                    <span>{{item.qcontent.slice(0,10)}}... </span>
                                </el-tooltip>
                            </div>
                            
                        </div>
                        <div class="intent-operation-btn-icon">
                            <el-tooltip class="item" effect="dark" :content="$t('intentQa.deleteAllQuestion')" placement="top-start" :open-delay="500">
                                <span
                                    class="ignore el-icon-minus"
                                    @click="ignoreAll(item.qid)">
                                </span>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" :content="$t('intentQa.addAllQuestion')" placement="top-start" :open-delay="500">
                                <span
                                    class="add iconfont guoran-tongyichicun-16-13-xinjian"
                                    @click="addAll(item.qid)"></span>
                            </el-tooltip>
                            
                        </div>
                    </div>
                    <div
                        :class="[
                            'item-recommend',
                            cindex + 1 ===
                            relatedQuestionList.list[index]
                                .relatedQuestions.length
                                ? 'last'
                                : '',
                        ]"
                        v-for="(
                            i, cindex
                        ) in relatedQuestionList.list[index]
                            .relatedQuestions"
                        :key="cindex"
                    >
                        <div class="title">
                            <div class="num">
                                {{ cindex + 1 }}
                            </div>
                            <div class="text-qa" v-if="i.content.length < 30">{{ i.content }}</div>
                            <el-tooltip class="item" effect="dark" :content="i.content" placement="top-start" v-else :open-delay="500">
                                <div class="text-qa">{{ i.content.slice(0,30)  }}...</div>
                            </el-tooltip>
                        </div>
                        <div class="intent-operation-btn-icon">
                            <el-tooltip class="item" effect="dark" :content="$t('intentQa.deleteLikeQuestion')" placement="top-start" :open-delay="500">
                                <span
                                    class="ignore el-icon-minus"
                                    @click="ignoreOne(i.id)"
                                ></span>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" :content="$t('intentQa.addLikeQuestion')" placement="top-start" :open-delay="500">
                                <span
                                    class="add iconfont guoran-tongyichicun-16-13-xinjian"
                                    @click="addOne(i.id)"
                                ></span>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
                <div
                    class="load-more"
                    v-if="
                        corpusRecommendPage <
                        relatedQuestionList.pages
                    "
                    @click="loadMore()"
                >
                    <span></span>{{$t('intentQa.loadMore')}}<span></span>
                </div>
                <div class="load-more" v-if="corpusRecommendPage >= relatedQuestionList.pages &&  relatedQuestionList.pages !==0">
                    {{$t('intentQa.noMore')}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:['isShowCorpusRecommend','corpusRecommendLoading','relatedQuestionList','reGetrecommendEmptyDataLoading','corpusList','recommendEmptyData','corpusRecommendPage'],
    methods:{
          //关闭问法推荐
        closeCorpusRecommend() {
            this.$emit('update:isShowCorpusRecommend',false)
        },
        ignoreAll(qId){
            this.$emit('ignoreAll',qId)
        },
        addAll(qId){
            this.$emit('addAll',qId)
        },
        ignoreOne(rqId){
            this.$emit('ignoreOne',rqId)
        },
        addOne(rqId){
            this.$emit('addOne',rqId)
        },
        loadMore(){
            this.$emit('loadMore')
        }
    }

}
</script>

<style lang="less" scoped>
@import "./../../../assets/less/main/common.less";
.right-qa-recomment{
    width: 340px;
    .recommend-header-top{
                display: flex;
                margin-left: 12px;
                .recommend-item{
                    width: 311px;
                    height: 37px;
                    background: @system_bgc_red;
                    border-radius: 5px 5px 0 0;
                    text-align: center;
                    line-height: 37px;
                    font-size: 14px;
                    color: #fff;
                    cursor: pointer;
                    display: flex;
                    padding: 0 6px 0 15px;
                    justify-content: space-between;
                    align-items: center;
                    i{
                        margin-left: 6px;
                    }
                    .el-icon-loading{
                        margin-right: 5px;
                    }
                }

    }
    .corpusRecommend{
        flex: none;
        margin-left: 12px;
        background: #FFFFFF;
        box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
        border-radius: 0 0 5px 5px;
        height: calc(100vh - 297px);
        overflow-y: auto;
        // width: 250px;
        &.no-data-list{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &.max-heigth{
            height: calc(100vh - 200px);
        }
        .header{
            height: 40px;
            line-height: 40px;
            background-color: #366AFF;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            color: #ffffff;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0 10px;
            .title{
                width: 50%;
                text-align: left;
                i{
                    font-size: 14px;
                    margin-left: 5px;
                }
            }
            .close{
                width: 50%;
                text-align: right;
                cursor: pointer;
                i{
                    font-size: 16px;
                }
            }
        }
        .corpus-content{
            // border:  1px solid #dddddd;
            border-radius: 5px;
            border-top: none;
            
            padding: 15px;
            // height: 100%;
            .EmptyData{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                // padding-top: 20%;
                height: 100%;
                img{
                    width: 80px;
                    height: 80px;
                    margin-bottom: 30px;
                }
                .title{
                    font-size: 16px;
                    margin-bottom: 26px;
                    color: #616161;
                }
                .desc{
                    color: #AAAAAA;
                    font-size: 12px;
                    color: #A9B3C6;
                }
            }
            .corpusRecommend-item{
                color: #000000;
                font-size: 14px;
                margin-bottom: 10px;
                overflow: hidden;
                .item-question,.item-recommend{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    height: 36px;
                    background: #FFFFFF;
                    border: 1px solid #85A5FF;
                    border-radius:5px;
                    align-items: center;
                    padding-right:5px;
                    width: 285px;
                    cursor: pointer;
                    &.item-recommend{
                        min-height: 36px;
                        height: auto;
                    }
                    >div{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                    }
                    .had-qa{
                        width: 79px;
                        height: 38px;
                        background: #85A5FF;
                        border-radius:5px;
                        margin-right: 10px;
                        text-align: center;
                        line-height: 40px;
                        color: #fff;
                        margin-left: -1px;
                    }
                    .title{
                        text-align: left;
                        // overflow: hidden;
                        // text-overflow:ellipsis;
                        // white-space: nowrap;
                        color: #606266;
                        width: calc(100% - 40px);
                        justify-content: flex-start;
                        .num{
                            background-color: #A1B9FF;
                            padding: 0 6px;
                            border-radius: 50%;
                            height: 20px;
                            line-height: 20px;
                            margin-right: 10px;
                            color: #fff;
                        }
                        .text-qa{
                            // line-height: 25px;
                            padding: 4px 0;
                            // width: 210px;
                                /*设置为弹性盒子*/
                            // -webkit-line-clamp: 1;
                            // /*最多显示3行*/
                            // overflow: hidden;
                            // /*超出隐藏*/
                            // text-overflow: ellipsis;
                            // /*超出显示为省略号*/
                            // -webkit-box-orient: vertical;
                            // word-break: break-all;
                        }

                    }
                    .ignore{
                        font-size: 12px;
                        color: @system_bgc_red;
                        cursor: pointer;
                        font-weight: 700;
                        margin-right: 10px;
                    }
                    
                    .add{
                        font-size: 12px;
                        // border: 1px solid #366AFF;
                        color: @system_bgc_red;
                        border-radius: 5px;
                        cursor: pointer;
                    }
                    .intent-operation-btn-icon{
                        display: none;
                    }
                    &:hover{
                        .intent-operation-btn-icon{
                            display: inline-block;
                        }  
                    }
                }
                .item-recommend{
                    border: none;
                    border-radius: 0;
                    border-bottom: 1px solid #E0E6F7;
                    &.last{
                        border: none;
                    }
                }
                .loading{
                    margin-top: 20px;
                    color: #606266;
                }
                &.isNewAdd{
                    animation: heightOpenAnimation 2s ease 1; /*调用动画：动画名、时间、时间线条、播放次数*/
                    animation-fill-mode: forwards;/*定义动画结束的状态*/
                }
                @keyframes heightOpenAnimation {
                    0%{max-height: 0;}
                    100%{max-height: 1000px;}
                }
            }
            
        }
        
        
    }  
}
             

</style>