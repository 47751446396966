<template>
    <div class="ask-drag-upload">
        <el-upload 
            class="upload-ask"  
            ref="upload" 
            :show-file-list="false" 
            :http-request="((data) => {return aliyunOssRequest(data, 'ask')})" 
            :before-upload="beforeUpload" 
            :on-exceed="uploadExceed" 
            :on-success="(res, file, fileList) => {uploadSuccess(file, fileList)}" 
            :on-remove="handleRemove" 
            :file-list="fileList" 
            :accept="acceptType"
            drag 
            multiple>
            <div class="adu-add">
                <span class="el-icon-plus"></span>
            </div>
            <div class="adu-add-des">
                <span>{{$t('common.draggingFiles')}}</span>
                <span>{{$t('common.draggingFiles2')}}</span>
            </div>
            <div class="adu-add-tip">{{$t('common.dragFileTip')}}</div>
        </el-upload>
        <div v-if="uploadInfoVisible" class="ask-upload-notify">
            <div class="ask-upload-notify-header">
                <div class="aunh-left">
                    <span v-show="uploadInfoStatus === 0" class="aunh-left-fail">
                        <i class="iconfont guoran-tongyichicun-cuowu"></i>
                    </span>
                    <span v-show="uploadInfoStatus === 1" class="aunh-left-loading">
                        <img height="18px" src="https://static.guoranbot.com/cdn-office-website/askbot_doc/loading.gif"
                            alt="" srcset="">
                    </span>
                    <span v-show="uploadInfoStatus === 2" class="aunh-left-suc">
                        <i class="iconfont guoran-a-tongyichicun-duihaoyuan"></i>
                    </span>
                    <span class="aunh-left-text">{{ uploadInfoStatusText }}</span>
                    <span class="aunh-left-count" v-if="uploadInfoStatus === 1">{{ uploadedCount }}/{{ uploadTotalCount }} </span>
                </div>
                <div class="aunh-right">
                    <span @click="hiddenActiveFileList = !hiddenActiveFileList" v-show="!hiddenActiveFileList"
                        class="aunh-right-up">
                        <i class="arsenal_icon arsenalangle-up-solid"></i>
                    </span>
                    <span @click="hiddenActiveFileList = !hiddenActiveFileList" v-show="hiddenActiveFileList"
                        class="aunh-right-down">
                        <i class="arsenal_icon arsenalangle-down-solid"></i>
                    </span>
                    <span @click="closeUploadInfoVisible" class="aunh-right-close">
                        <!-- <i class="iconfont guoran-bukejian" v-if="uploadInfoStatus === 1"></i> -->
                        <i class="iconfont guoran-cuohao2"></i>
                    </span>
                </div>
            </div>
            <div v-show="hiddenActiveFileList" class="ask-upload-notify-body">
                <div class="aunb-info" v-show="uploadInfoStatus === 0">
                    <!-- <span class="aunb-info-add">共上传</span> -->
                   <div class="left">
                        <span class="aunb-info-suc&err">成功 : {{uploadedCount}} 失败 : {{uploadedFailCount}}</span>
                   </div>
                   <div class="right" @click="reUpload">重新上传所有失败任务</div>
                </div>
                <div v-for="(item,index) in activeFileList" :key="item.uid" class="aunb-cell">
                    <div :style="{ width: (item.process * 350) + 'px' }" class="aunb-cell-process"></div>
                    <div class="aunb-cell-info">
                        <div class="aunb-cell-info-left">
                            <span v-show="item.process === 1" class="acil-suc">
                                <i class="iconfont guoran-a-tongyichicun-duihaoyuan"></i>
                            </span>
                            <span v-show="item.process === 0" class="acil-err">
                                <i class="iconfont guoran-tongyichicun-cuowu"></i>
                            </span>
                            <span class="acil-icon">
                                <img width="16px" style="margin-top: 2px;" :src="item.icon" alt="" srcset="">
                            </span>
                            <span :class="['acil-name',item.process !== 0 ? 'min' : 'max']">
                                <span class="one-column-ellipsis"> {{ item.name }}</span>
                                <span v-show="item.isBeyond" class="beyond">图片大小超出10M </span>
                                <span v-show="item.isRepeatName" class="beyond">该文件名的图片已存在</span>
                                <span v-show="item.notAllowType && item.notAllowType !== ''" class="beyond">暂不支持上传{{notAllowType}}类型的图片</span>
                            </span>
                        </div>
                        <div class="aunb-cell-info-right" v-if="item.process !== 0">
                            <span class="acir-current">{{ formatSize(item.size * item.process) }}</span>
                            <span class="acir-total">/{{ formatSize(item.size) }}</span>
                            <span v-if="item.process !== 1" class="acir-close" @click="stopUpload(item,index)">
                                <i class="iconfont guoran-tongyichicun-cuowu"></i>
                            </span>
                            <span class="acir-reload" v-else   @click="previewImage(item)">
                                <i class="iconfont guoran-wenjianjia-zhankaizhuangtai--folderOpen"></i>
                            </span>
                            <!-- <span class="acir-reload" v-if="item.isBeyond">
                                <i class="iconfont guoran-tongyichicun-huifuchuli"></i>
                            </span> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import formatDoc from "../formatDocList";
// import { requestUrl } from "./../../http/requestUrl";
import { ossConfig, multipartUpload, ossFileUrl } from "../../ChatFlow/component/answer/js/AliyunIssUtilWithProcess";
// import { ossConfig, multipartUpload, ossFileUrl } from "../../../utils/AliyunlssUtils";

export default {
    name: "DragUpload",
    props: {
        // 图片列表
        imageList: {
            type: Array,
            default: () => {
                return []
            }
        },
        uploadSuccessItem:{
            type:Object,
            default(){
                return {}
            }
        }

    },
    data() {
        return {
            uploadInfoVisible: false,
            uploadInfoStatus: 1, // 1 上传中 2 上传成功
            uploadInfoStatusText: "上传中", // 上传中 上传成功
            fileList: [],
            imageUrl: "",
            // 当前上传文件列表信息 用于右上提示展示
            activeFileList:[],
            // 上传成功文件数量
            uploadedCount: 0,
            // 总上传数量
            uploadTotalCount: 1,
            hiddenActiveFileList: true,
            uploadedFailCount:0, // 上传失败的数量
            showViewer:false,
            stopUploadObj:{},
            acceptType:".jpg, .JPG, .jpeg, .JPEG, .png, .PNG",
            fileList:[]
        }
    },
    watch:{
        uploadSuccessItem:{
            handler(n){
                setTimeout(() => {
                    this.activeFileList.forEach(item => {
                        if(n.imageUrl === item.imageUrl){
                            item.id = n.id
                        }
                    })
                     this.$forceUpdate();
                },1000)
            },
            deep:true,
            immediate:true
        },
    },
    methods: {
        previewImage(item){
            this.$emit('previewImage',item)
        },
        reUpload(){
            let list = this.activeFileList.filter(item => {return item.process === 0 && !item.isBeyond && !item.isRepeatName && item.notAllowType === ''});
            this.fileList = list;
            list.forEach(listItem => {
                let res = multipartUpload(ossConfig, listItem.file, (process) => {
                    let flag = -1;
                    list.forEach(item => {
                        if (item.uid === listItem.file.uid) {
                            item.process = process;
                        }
                        if(item.process === 1){
                            this.uploadedFailCount--;
                            this.uploadedCount++;
                            this.uploadTotalCount++;
                        } 
                    })
                    if (flag === 0) {
                        this.uploadInfoStatus = 0;
                        this.uploadInfoStatusText = "部分任务上传失败";
                    } else if (flag === 1) {
                        this.uploadInfoStatus = 1;
                        this.uploadInfoStatusText = "上传中";
                    } else {
                        this.uploadInfoStatus = 2;
                        this.uploadInfoStatusText = "上传成功";
                    }
                });
                res.then((data) => {
                    this.$emit("uploadSuccess", {
                        uid: listItem.file.uid,
                        id: listItem.file.uid,
                        size:listItem.file.size,
                        name:listItem.file.name,
                        raw:listItem.file,
                        // response:data
                    });
                })
            })
        },
        formatSize(size) {
            let sizeB = size * 1024 * 1024;
            let str = "";
            if (sizeB < 1024) {
                str = sizeB.toFixed(1) + "B";
            } else {
                if (size < 1) {
                    str = (sizeB / 1024).toFixed(1) + "KB";
                } else {
                    str = Number(size).toFixed(1) + "M";
                }
            }
            return str;
        },
        closeUploadInfoVisible() {
            this.uploadInfoVisible = false;
            this.activeFileList = [];
            this.uploadedCount = 0;
            this.uploadedFailCount = 0;
            this.uploadTotalCount = 0;
        },
        aliyunOssRequest(data) {
            this.uploadInfoVisible = true;
            let _that = this;
            let file = data.file;
            let isCanUpload = true;
            this.activeFileList.forEach(item => {
                if(file.uid === item.uid){
                    file = item.file
                }
            })
            // process 进度
            let res = multipartUpload(ossConfig, file, (process) => {
                console.log(file,'multipartUpload',_that.activeFileList);
                // if(!isCanUpload){
                //     _that.$refs.upload.abort();
                // }
                
                let flag = -1;
                this.uploadedCount = 0;
                this.uploadedFailCount = 0;
                _that.activeFileList.forEach(item => {
                    if (item.uid === file.uid) {
                        item.process = process;
                    }
                    // 0 失败  1 成功
                    if(item.process === 0){
                        flag = 1;
                        this.uploadedFailCount++;
                    } else if (item.process !== 1) {
                        flag = 1;
                    } else {
                        this.uploadedCount++;
                    }
                    if (item.isBeyond || item.isRepeatName) {
                        flag = 0;
                    }
                })
                if(this.stopUploadObj.uid){
                    isCanUpload = false;
                } else {
                    if (flag === 0) {
                        this.uploadInfoStatus = 0;
                        this.uploadInfoStatusText = "部分任务上传失败";
                    } else if (flag === 1) {
                        this.uploadInfoStatus = 1;
                        this.uploadInfoStatusText = "上传中";
                    } else {
                        this.uploadInfoStatus = 2;
                        this.uploadInfoStatusText = "上传成功";
                    }
                    this.uploadTotalCount = _that.activeFileList.length;
                }
                if (!isCanUpload){
                    this.stopUploadObj = {};
                    return false;
                } 
            });
            if (!isCanUpload){
                this.stopUploadObj = {};
                return false;
            } else {
                return res
            }
        },
        formatUploadFileInfo(fileInfo,isBeyond,isRepeatName,notAllowType) {
            let tampFileInfo = {
                oldSize:fileInfo.size,
                size: (fileInfo.size / 1024 / 1024).toFixed(2),
                icon: formatDoc.formatFileTypeSrc(fileInfo.type),
                name: fileInfo.name,
                uid: fileInfo.uid,
                process: 0,
                file:fileInfo,
                isBeyond:isBeyond,
                oldSize:fileInfo.size,
                isRepeatName:isRepeatName,
                notAllowType:notAllowType
            };
            return tampFileInfo;
        },

        async beforeUpload(file) {
            // let allowType = ['pdf', 'txt', 'docx', 'doc', 'xlsx', 'xls', 'jpg', 'jpeg', 'png', 'webp', 'gif', 'svg', 'bmp', 'avi', 'mov', 'rmvb', 'wav', 'mp3', 'mp4', 'md', 'html', 'pptx', 'ppt', 'wpt', 'wps', 'docm', 'dotm', 'dotx', 'rtf', 'csv'];
            let allowType = ['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG'];
            let newFile = file;
            let size = newFile.size;
            let tampNameArr = newFile.name.split('.');
            let fileType = tampNameArr[tampNameArr.length - 1];
            
            let flag = !(size <= 10*1024*1024);
           
           
            let notAllowType = '';
            if (allowType.indexOf(fileType) === -1) {
                flag = true;
                notAllowType = fileType;
                // this.$message.warning(`您好，暂不支持上传 ${fileType} 类型文件！`,2000);
                // return false;
            }
            // 大于3M的图片需要压缩以后再上传
            if(!flag && 3*1024*1024 < size){
                console.log(file,'大于4M的图片需要压缩以后再上传');
                await this.imgCompress(newFile,'image/jpeg',0.6).then(res => {
                    newFile = res.file;
                    newFile.uid = file.uid;
                    size = res.file.size;
                    console.log(newFile,'压缩后的文件对象');//压缩后的文件对象
                }).catch(e=>{
                    console.log(e);
                })
            } 
            console.log(newFile,'newFile');
            let isRepeatName = false;
            this.imageList.forEach(item => {
                if(item.imageName === newFile.name){
                    flag = true;
                    isRepeatName = true;
                }
            })
            if (flag) {
                console.log('错误');
                this.uploadedFailCount++
                this.activeFileList.unshift(this.formatUploadFileInfo(newFile,!(size <= 10*1024*1024),isRepeatName,notAllowType));
                this.uploadInfoStatus = 0;
                this.uploadInfoStatusText = "部分任务上传失败";
                this.uploadInfoVisible = true;
                return false;
            } else {
                console.log('正确');
                this.activeFileList.unshift(this.formatUploadFileInfo(newFile,false,false,''));
            }
        },
        /**
         * 图片压缩方法
         * @method imgCompress
         * @param {imgFile:图片原文件} file
         * @param {imgType:压缩后的图片的格式，可选image/webp或image/jpeg，其他格式无效} string
         * @param {quality：压缩比例，0~1，仅当imgType为有效值时生效} number
         * @return 调用父组件方法 保存模板管理 模板
         */
        imgCompress(imgFile,imgType,quality) {
            if(!imgType){
                imgType = 'image/jpeg';//默认输出webp格式的图片
            }
            let _that = this;
            return new Promise((resolve,reject)=>{
                let src=URL.createObjectURL(imgFile);
                let img=new Image();//创建一个img标签
                img.src=src;
                img.onload=function () {
                    try {
                        URL.revokeObjectURL(src);//释放内存
                        let width=this.width;
                        let height=this.height;
                        let imgCanvas=document.createElement('canvas');//创建canvas元素
                        imgCanvas.width=width;
                        imgCanvas.height=height;
                        let ctx=imgCanvas.getContext('2d');//2d渲染
                        ctx.drawImage(this,0,0,width,height);//将图片以原有尺寸绘制到canvas中
                        //转换成base64并压缩，可以从0到1的区间内选择图片的质量。如果超出取值范围，将会使用默认值0.92。其他参数会被忽略
                        if(imgType==='image/jpeg'){
                            imgCanvas.toBlob(function (b) {
                                let compressUrl=URL.createObjectURL(b);//压缩后的文件url
                                let fileName=imgFile.name.substring(0,imgFile.name.lastIndexOf('.'))+'.'+imgType.split('/')[1];//重构文件名
                                let file=_that.blobToFile(b,fileName,imgType);//Blob实例转成File实例
                                resolve({
                                    url:compressUrl,
                                    file:file,
                                });
                                imgCanvas=null;//释放内存
                            },imgType,quality);
                        } else {
                            imgCanvas.toBlob(function (b) {
                                let compressUrl=URL.createObjectURL(b);
                                let fileName=imgFile.name.substring(0,imgFile.name.lastIndexOf('.'))+'.'+imgType.split('/')[1];
                                let file=_that.blobToFile(b,fileName,imgType);
                                resolve({
                                    url:compressUrl,
                                    file:file,
                                },imgType);
                                imgCanvas=null;//释放内存
                            },imgType);
                        }
                    }catch (e) {
                        reject(e);
                    }
                }
            })
        },
        //blob转file
        blobToFile(blob,fileName,type) {
            return new window.File([blob], fileName, {type: type});
        },
        uploadExceed() {
            this.$message.warning('超出可上传的最大数量',2000);
            return
        },
        uploadSuccess(file) {
            console.log(file,imageUrl,'上传成功');
             let imageUrl = ossFileUrl(ossConfig, file.response.name);
            this.activeFileList.forEach(item => {
                if(item.uid === file.uid){
                    item.imageUrl = imageUrl;
                }
            })
            this.$emit("uploadSuccess", {
                uid: file.uid,
                size:file.size,
                name:file.name,
                raw:file,
                imageUrl:imageUrl,
                // response:file.response
            });
        },
        handleRemove(file, fileList) {
            console.log(file, fileList,'handleRemove');
        },
        stopUpload(item,index){
            this.stopUploadObj = item;
            console.log(item,this.$refs.upload);
            this.activeFileList.splice(index,1);
            if(this.activeFileList.length === 0){
                this.uploadInfoVisible = false;
            }
        },
    },
}
</script>
<style lang="less" scoped>
@import "../../../assets/less/main/common.less";

.drag-upload-phone {
    margin: 50px auto 0;
    width: 240px;
    height: 31px;
    line-height: 31px;
    color: white;
    background: #366AFF;
    border-radius: 15px;
    cursor: pointer;
}

.ask-upload-notify {
    z-index: 10;
    position: absolute;
    min-height: 50px;
    bottom: 18px;
    right: 16px;
    width: 200px;
    width: 351px;
    background: #FFFFFF;
    box-shadow: 0px 1px 9px 0px rgba(57, 63, 79, 0.21);
    border-radius: 5px;

    .ask-upload-notify-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        line-height: 48px;

        .aunh-left {
            flex: auto;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 48px;
            padding-left: 12px;
            font-size: 12px;

            .aunh-left-fail{
                color: #FE5965;
                font-size: 14px;
                font-weight: 700;
                margin-right: 5px;
            }

            .aunh-left-loading {
                margin-top: 8px;
                margin-right: 5px;
            }

            .aunh-left-suc {
                color: #04B051;
                font-weight: 700;
                margin-right: 5px;
            }

            .aunh-left-err {
                color: #FE5965;
                font-weight: 700;
                margin-right: 5px;
            }

            .aunh-left-count {
                height: 17px;
                line-height: 17px;
                padding: 0 5px;
                background: #ECF1FF;
                border-radius: 9px;
                margin: 0 5px;
            }
        }

        .aunh-right {
            flex: none;
            width: 80px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-right: 12px;

            .aunh-right-up,.aunh-right-down {
                font-size: 12px;
                font-weight: lighter;
                cursor: pointer;
                color: #141414bd;
                i{
                    font-size: 16px;
                }
            }

            .aunh-right-close {
                margin-left: 6px;
                color: #A9B3C6;
                cursor: pointer;
            }
        }
    }

    .ask-upload-notify-body {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-bottom: 12px;
        max-height: 200px;
        overflow-y: auto;
        overflow-x: hidden;

        .aunb-info {
            height: 24px;
            line-height: 24px;
            text-align: left;
            padding: 0 12px;
            font-size: 12px;
            font-weight: bolder;
            background-color: rgba(54, 106, 255, .07);
            margin-bottom: 5px;
            display: flex;
            justify-content: space-between;
            .right{
                color: #366AFF;
                font-size: 12px;
                cursor: pointer;
            }
        }

        .aunb-cell {
            height: 32px;
            width: 100%;
            margin: 4px 0;
            position: relative;

            .aunb-cell-process {
                position: absolute;
                left: 0;
                top: 0;
                height: 32px;
                border-radius: 16px;
                width: 50%;
                background-color: rgba(54, 106, 255, .07);
            }

            .aunb-cell-info {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 12px;
                height: 100%;
                line-height: 100%;
                width: 320px;
                position: relative;
                z-index: 2;

                .aunb-cell-info-left {
                    flex: auto;
                    justify-content: flex-start;
                    align-items: center;
                    display: flex;

                    .acil-suc {
                        color: #95E0B7;
                        margin-right: 6px;
                    }

                    .acil-err {
                        color: #FFA8AE;
                        margin-right: 6px;
                    }

                    .acil-name {
                        font-size: 14px;
                        // font-weight: 700;
                        margin-right: 6px;
                        margin-left: 6px;
                        text-align: left;
                        &.min{
                            max-width: 160px;
                            min-width: 155px;
                        }
                        &.max{
                            width: 270px;
                        }
                        .beyond{
                            margin-top: 4px;
                            color:#FE5965;
                            font-size: 12px;
                        }
                    }
                }

                .aunb-cell-info-right {
                    flex: none;
                    width: 80px;
                    justify-content: flex-end;
                    align-items: center;
                    display: flex;
                    font-size: 12px;
                    height: 32px;
                    line-height: 32px;

                    .acir-current {
                        font-weight: bolder;
                        color: #366AFF;
                    }

                    .acir-total {
                        color: #A9B3C6;
                        margin-right: 6px;
                    }

                    .acir-close {
                        color: #A9B3C6;
                    }

                    .acir-reload {
                        color: #A9B3C6;
                        
                    }
                    .upload-list-preview-image{
                        width: 16px;
                        height: 16px;
                        position: absolute;
                        right: 12px;
                        top: 9px;
                        background: transparent;
                        z-index: 2;
                        .el-image__inner{
                            visibility: hidden ;
                        }
                    }

                }
                .guoran-wenjianjia-zhankaizhuangtai--folderOpen{
                    cursor: pointer;
                }
            }
        }

    }
}

.ask-drag-upload {
    // height: 100%;
    // width: 100%;
    box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
    border-radius: 5px;
    overflow: hidden;
    width: 170px;
    height: 170px;
    // border: 1px dashed #A1B9FF;
    border: dashed;
    border-color: #A1B9FF;
    border-width: 2px;
    border-radius: 5px;
    font-family: Microsoft YaHei;

    /deep/.el-upload-dragger {
        width: 170px;
        height: 170px;
        display: flex;
        flex-direction: column;
        border: none;
        background-color:transparent;

        .adu-add {
            margin-top: 35px;

            .el-icon-plus {
                font-size: 34px;
                color: #366AFF;
            }
        }

        .adu-add-des {
            height: 30px;
            display: flex;
            flex-direction: column;
            margin-top: 12px;
            font-size: 12px;
            color: #616161;
        }

        .adu-add-tip {
            font-size:12px;
            zoom:0.83;
            font-weight: 400;
            color: #999999;
            line-height: 16px;
            height: 32px;
            background: #F2F5FD;
            border-radius: 0px 0px 5px 5px;
            margin-top: 38px;
        }
    }
}
</style>
  