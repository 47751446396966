//guide.js
const steps = [
    {
        element: ".headerName",
        popover: {
          title: "机器人知识库配置页面",
          description: "您已进入机器人知识库配置页面，跟着我一步一步来配置，让机器人更加聪明",
          position: "bottom"
        }
    },
    {
      element: ".qwType",
      popover: {
        title: "添加问法",
        description: "问法：要想让机器人能识别很多用户的问题，需要尽可能的把多的用户可能的提问添加到知识库中，机器人会不断迭代模型",
        position: "bottom"
      }
    },
    {
      element: ".add-q-outer",
      popover: {
        title: "添加问法",
        description: "将用户可能会问的问法添加至输入框回车即可添加问法",
        position: "top"
      }
    },
    {
      element: "#batchOperation",
      popover: {
        title: "批量添加",
        description: "如果问法很多，可以放在文本文件中，每行一个，批量导入系统",
        position: "top"
      },
    },
    {
      element: "#ruleRecognition",
      popover: {
        title: "添加规则",
        description: "规则：规则没有使用人工智能技术，而且是基于关键词等规则的匹配，规则在问法不多时建议添加，具体添加方法，点击添加规则查看示例",
        position: "bottom"
      }
    },
    {
      element: "#tab-second",
      popover: {
        title: "机器人回复配置",
        description: "机器人识别不同问法能力配置完，需要配置机器人怎么回复用户的问题，点击这里进行配置",
        position: "bottom"
      }
    },
  ]
   
  export default steps